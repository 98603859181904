const list = [{
        name: 'Jinna',
        photo: require('@/assets/img/Unknown.png'),
        links: [{
            type: 'telegram',
            name: '@WGJinna',
            link: 'https://t.me/WG_Wian1'
        }, {
            type: 'sky',
            name: 'Jinna@wg.com',
            link: ''
        }, {
            type: 'email',
            name: 'Jinna@wg.com',
            link: 'mailto:Jinna@wg.com'
        }]
    },
    {
        name: 'Kurt',
        photo: require('@/assets/img/Unknown1.png'),
        links: [{
            type: 'telegram',
            name: '@WGKurt',
            link: 'https://t.me/WG_Wian1'
        }, {
            type: 'sky',
            name: 'Kurt@wg.com',
            link: ''
        }, {
            type: 'email',
            name: 'Kurt@wg.com',
            link: 'mailto:Kurt@wg.com'
        }]
    },
    {
        name: 'Wian',
        photo: require('@/assets/img/Unknown2.png'),
        links: [{
            type: 'telegram',
            name: '@WG_Wian1',
            link: 'https://t.me/WG_Wian1'
        }, {
            type: 'sky',
            name: 'Wian@wg.com',
            link: ''
        }, {
            type: 'email',
            name: 'Wian@wg.com',
            link: 'mailto:Wian@wg.com'
        }]
    },

    {
        name: 'Tempo',
        photo: require('@/assets/img/Unknown3.png'),
        links: [{
            type: 'telegram',
            name: '@WGTempo',
            link: 'https://t.me/WG_Wian1'
        }, {
            type: 'sky',
            name: 'Tempo@wg.com',
            link: ''
        }, {
            type: 'email',
            name: 'Tempo@wg.com',
            link: 'mailto:Tempo@wg.com'
        }]
    },

    {
        name: 'Uni',
        photo: require('@/assets/img/Unknown4.png'),
        links: [{
            type: 'telegram',
            name: '@WGUni',
            link: 'https://t.me/WG_Wian1'
        }, {
            type: 'sky',
            name: 'Uni@wg.com',
            link: ''
        }, {
            type: 'email',
            name: 'Uni@wg.com',
            link: 'mailto:Uni@wg.com'
        }]
    },

    {
        name: 'Lauren',
        photo: require('@/assets/img/Unknown5.png'),
        links: [{
            type: 'telegram',
            name: '@WGLauren',
            link: 'https://t.me/WG_Wian1'
        }, {
            type: 'sky',
            name: 'Lauren@wg.com',
            link: ''
        }, {
            type: 'email',
            name: 'Lauren@wg.com',
            link: 'mailto:Lauren@wg.com'
        }]
    }
]
export default list