import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/all.scss'
import ElementUI from "element-ui"
import 'element-ui/lib/theme-chalk/index.css';
import i18n from '@/lang/index.js'

Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app')