<template>
  <div @click="langShow = false" v-if="isHtml">
    <!-- 头部 -->
    <header class="wg-wid wg-header">
      <div class="wg-wid-16 wg-header-top">
        <div class="left">
          <span class="login"></span>
          <span class="slogin" :class="setlangVal"></span>
        </div>
        <transition name="h5menu">
          <div class="center" v-if="styleH5" @click="openH5">
            <ul :class="styleH5 ? 'h5menurun' : ''">
              <li
                v-for="(item, index) in getmenu"
                :key="index"
                :class="{ hover: item.link === menuMap }"
                @click.stop="jumpMao(item)"
                :style="'animation-delay:' + (index * 0.1 + 's')"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </transition>
        <div class="right">
          <div class="wg-lang" @click.stop="langShow = !langShow">
            <span class="text">{{ targetLangVal }}</span>
            <span class="arrow" :class="{ xz: langShow }"></span>
          </div>
          <div class="wg-langs" v-if="langShow">
            <ul>
              <li
                v-for="(item, index) in langs"
                :key="index"
                :class="{ hover: item.type === setlangVal }"
                @click="tabLange(item)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>

          <div class="bw-h5-menus" @click="openH5">
            <img src="@/assets/img/11.png" />
          </div>
        </div>
      </div>
    </header>
    <!-- banner -->
    <a id="about"></a>
    <section
      class="wg-wid wg-banner wow fadeIn about"
      data-wow-duration="1s"
      data-wow-delay="1"
    >
      <!-- <div class="wg-wid-16"></div> -->
      <div class="wg-banner-text">
        {{ $t("views.Index.523742-2") }}
      </div>
      <img
        :src="require('@/assets/img/langs/' + setlangVal + '/banner.jpg')"
        class="isPc"
      />
      <img
        :src="require('@/assets/img/langs/' + setlangVal + '/banner1.jpg')"
        class="isH5"
      />
    </section>
    <!-- {{ $t('views.Index.523742-3') }} -->
    <a id="superbw"></a>
    <section class="wg-wid superbw">
      <div class="wg-wid-16">
        <div class="wg-title">
          <h3 class="title">{{ $t("views.Index.523742-3") }}</h3>
          <span class="line"></span>
          <span class="row" @click="tabJump(4)"
            >{{ $t("views.Index.523742-4") }} <span class="arrow"></span
          ></span>
          <span class="line"></span>
          <a href="https://www.google.com" target="_blank" class="row"
            >{{ $t("views.Index.523742-5") }}<span class="arrow"></span
          ></a>
        </div>
      </div>
      <div
        class="wg-wid-16 wg-super wow fadeIn"
        data-wow-duration="1s"
        data-wow-delay="1"
      >
        <div class="left">
          <ul>
            <li
              v-for="(item, index) in getNav"
              :key="index"
              color="wow fadeInUp"
              data-wow-duration="2s"
              data-wow-delay="5s"
              :class="{ hover: index === navIndex }"
              @click="navIndex = index"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="right">
          <div class="bw-banner-title">{{ $t("views.Index.523742-6") }}</div>
          <!-- 第一个 -->
          <transition name="run">
            <div
              class="bw-super-box bw-super-right"
              :class="navIndex === 0 ? 'zindex' : ''"
              v-show="navIndex === 0"
            >
              <h3>
                <strong>{{ $t("views.Index.523742-7") }}</strong>
                {{ $t("views.Index.523742-8") }}
              </h3>
              <div class="bw-super-row">
                <div class="row">
                  <img
                    :src="
                      require('@/assets/img/langs/' + setlangVal + '/dan.png')
                    "
                  />
                  <p>{{ $t("views.Index.523742-9") }}</p>
                </div>
                <div class="row">
                  <img src="@/assets/img/dan1.png" />
                  <p>{{ $t("views.Index.523742-10") }}</p>
                </div>
              </div>
            </div>
          </transition>
          <!-- 第二个 -->
          <div class="bw-banner-title">{{ $t("views.Index.523742-11") }}</div>
          <transition name="run">
            <div
              class="bw-super-2k bw-super-right"
              :class="navIndex === 1 ? 'zindex' : ''"
              v-show="navIndex === 1"
            >
              <Banner :list="twos" />
            </div>
          </transition>
          <!-- 第三个 -->
          <div class="bw-banner-title">{{ $t("views.Index.523742-12") }}</div>
          <transition name="run">
            <div
              class="bw-super-2k bw-super-right"
              :class="navIndex === 2 ? 'zindex' : ''"
              v-show="navIndex === 2"
            >
              <Banner :list="threes" />
            </div>
          </transition>
          <!-- 第4个 -->
          <div class="bw-banner-title">{{ $t("views.Index.523742-13") }}</div>
          <transition name="run">
            <div
              class="bw-super-dom bw-super-right"
              :class="navIndex === 3 ? 'zindex' : ''"
              v-show="navIndex === 3"
            >
              <div class="bw-demo-box">
                <el-table :data="getFour" border>
                  <el-table-column :label="$t('views.Index.523742-14')">
                    <template slot-scope="scope">
                      <img :src="scope.row.icon" />
                      <span>{{ scope.row.name }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="lang"
                    :label="$t('views.Index.523742-15')"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="sec"
                    :label="$t('views.Index.523742-16')"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="number"
                    :label="$t('views.Index.523742-17')"
                  >
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </transition>
          <!-- 第5个 -->
          <a id="h5money"></a>
          <div class="bw-banner-title">{{ $t("views.Index.523742-18") }}</div>
          <transition name="run">
            <div
              class="bw-super-over bw-super-right"
              :class="navIndex === 4 ? 'zindex' : ''"
              v-show="navIndex === 4"
            >
              <div class="bw-demo-box">
                <el-table
                  v-if="fiveLists"
                  :data="fiveLists"
                  border
                  width="100%"
                  :span-method="objectSpanMethod"
                  class="wg-table"
                >
                  <el-table-column
                    prop="name"
                    :label="$t('views.Index.523742-19')"
                    align="center"
                  >
                    <!-- 
                    :filters="gameTypes"
                    :filter-method="filterTag" -->
                    <template slot-scope="scope"
                      >{{ scope.row.name }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="$t('views.Index.523742-20')"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <div class="row-wg">
                        <img
                          :src="
                            require('@/assets/img/gameicon/' +
                              scope.row.platyType +
                              '.png')
                          "
                          alt=""
                        />
                        <span>{{ scope.row.platyname }}</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="current"
                    :label="$t('views.Index.523742-21')"
                    align="center"
                  >
                  </el-table-column>
                  <el-table-column
                    prop="bl"
                    :label="$t('views.Index.523742-22')"
                    align="center"
                  >
                  </el-table-column>
                </el-table>
                <div class="hw-super-sec">
                  <h4>{{ $t("views.Index.523742-4") }}</h4>
                  {{ $t("views.Index.523742-23") }}<br />
                  <strong> {{ $t("views.Index.523742-24") }}</strong
                  >{{ $t("views.Index.523742-25") }}<br />
                  <strong>{{ $t("views.Index.523742-26") }}</strong
                  >{{ $t("views.Index.523742-27") }} <br />
                  <strong>{{ $t("views.Index.523742-28") }}</strong> <br />
                  <strong>{{ $t("views.Index.523742-29") }}</strong>
                  {{ $t("views.Index.523742-30") }}
                </div>
              </div>
            </div>
          </transition>
          <!-- 第6个 -->
          <div class="bw-banner-title">{{ $t("views.Index.523742-31") }}</div>
          <transition name="run">
            <div
              class="bw-super-dom bw-super-right"
              :class="navIndex === 5 ? 'zindex' : ''"
              v-show="navIndex === 5"
            >
              <img
                :src="
                  require('@/assets/img/langs/' + setlangVal + '/banner01.png')
                "
                class="img-wid"
              />
            </div>
          </transition>
          <!-- 第7个 -->
          <div class="bw-banner-title">{{ $t("views.Index.523742-32") }}</div>
          <transition name="run">
            <div
              class="bw-super-dom bw-super-right"
              :class="navIndex === 6 ? 'zindex' : ''"
              v-show="navIndex === 6"
            >
              <img
                :src="
                  require('@/assets/img/langs/' + setlangVal + '/banner02.png')
                "
                class="img-wid"
              />
            </div>
          </transition>
        </div>
      </div>
    </section>
    <!-- {{ $t('views.Index.523742-33') }} -->
    <a id="games"></a>
    <section class="wg-wid wg-game games">
      <div class="wg-wid-16">
        <div class="wg-title">
          <h3 class="title">{{ $t("views.Index.523742-33") }}</h3>
          <span class="line"></span>
          <ul class="navs">
            <li
              v-for="(item, index) in getGames"
              :key="index"
              :class="{ hover: index === gameIndex }"
              @click="openGameType(item, index)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="bw-wid-16">
          <ul class="wg-game-list" v-if="testList">
            <li
              v-for="(item, index) in testList"
              :key="index"
              class="wow bounceIn"
              data-wow-duration="1.2s"
            >
              <div class="img">
                <div class="no-link" v-if="!item.link">
                  <span class="icon-1"></span>
                  <p>{{ $t("views.Index.523742-34") }}</p>
                </div>
                <a class="yes-link" v-else :href="item.link" target="_blank">
                  <span class="icon-1"></span>
                </a>
                <img :src="item.bigImgVal" class="bigimg" />
              </div>
              <div class="info">
                <div class="info-left">
                  <img :src="item.smallImg" class="icon" />
                </div>
                <div class="info-center">
                  <h4>{{ item.name }}</h4>
                  <p>{{ $t("views.Index.523742-35") }}{{ item.size }}</p>
                </div>
                <div class="info-right">
                  <div class="imgs">
                    <img src="@/assets/img/1.jpg" />
                    <img src="@/assets/img/2.png" />
                    <img src="@/assets/img/3.jpg" />
                    <img src="@/assets/img/4.jpg" />
                    <img src="@/assets/img/5.jpg" />
                  </div>
                  <p class="rtp" v-if="item.rtp">RTP:{{ item.rtp }}</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- {{ $t('views.Index.523742-36') }} -->
    <a id="cooperate"></a>
    <section class="wg-wid wg-game cooperate">
      <div class="wg-wid-16">
        <div class="wg-title">
          <h3 class="title">{{ $t("views.Index.523742-36") }}</h3>
          <span class="line"></span>
          <div
            class="sec wow flipInY"
            data-wow-duration="1.2s"
            data-wow-delay=".8s"
          >
            {{ $t("views.Index.523742-37") }}
          </div>
        </div>
      </div>
      <div class="wg-wid-16">
        <ul class="wg-pathar">
          <li
            class="wg-pathar-row wow bounceIn"
            v-for="item in 12"
            :key="item"
            data-wow-duration=".7s"
          >
            <i :class="'row' + item"></i>
          </li>
        </ul>
      </div>
    </section>
    <!-- {{ $t('views.Index.523742-38') }} -->
    <a id="Business"></a>
    <section
      class="wg-wid wg-game wow fadeIn Business"
      data-wow-duration="1.2s"
    >
      <div class="wg-wid-16">
        <div class="wg-title">
          <h3 class="title">{{ $t("views.Index.523742-38") }}</h3>
          <span class="line"></span>
          <div
            class="sec wow flipInY"
            data-wow-duration="1.2s"
            data-wow-delay="1.2s"
          >
            {{ $t("views.Index.523742-39") }}
          </div>
        </div>
      </div>
      <div class="wg-wid-16">
        <ul class="wg-contact">
          <li
            v-for="(item, index) in contactList"
            :key="index"
            class="wow bounceIn"
            data-wow-duration=".7s"
          >
            <div class="left">
              <div class="photo">
                <img :src="item.photo" />
              </div>
              <p>{{ item.name }}</p>
            </div>
            <div class="line"></div>
            <div class="right">
              <dl v-for="(row, rowIndex) in item.links" :key="rowIndex">
                <dd :class="row.type"></dd>
                <dt>
                  <a :href="row.link" target="_black">{{ row.name }}</a>
                </dt>
              </dl>
            </div>
          </li>
        </ul>
      </div>
    </section>
    <!-- 版权信息 -->
    <div class="wg-wid wg-footer">
      Copyright © 2024 Win Gaming All Rights Reserved
    </div>
    <!-- 活动 -->
    <div class="wg-pop" v-if="showPop">
      <div class="wg-pop-main">
        <h3>{{ $t("views.Index.940813-0") }}</h3>
        <div class="close" @click.stop="showPop = false"></div>
        <div class="wg-pop-over" v-html="activiVal"></div>
      </div>
    </div>
  </div>
</template>
<script>
import WOW from "wowjs";
import contactsList from "./data/contact.js"; // 联系列表数据
import Banner from "@/components/Banner.vue";
import { mapState } from "vuex";
import listtext from "./data/five.js";
import gameList from "./data/games.js";
export default {
  name: "HomeView",
  mixins: [listtext, gameList],
  components: {
    Banner,
  },
  data() {
    return {
      showPop: true,
      activiVal: null,
      bannerVal: 0,
      langs: [
        {
          name: "简体中文",
          type: "zh",
        },
        {
          name: "繁体中文",
          type: "hk",
        },
        {
          name: "English",
          type: "en",
        },
        {
          name: "ภาษาไทย",
          type: "tk",
        },
        {
          name: "Tiếng Việt",
          type: "tv",
        },
      ],
      langIndex: 0,
      menuIndex: 0,
      langShow: false,
      navIndex: 0,
      gameIndex: 0,
      gameList: null,
      contactList: contactsList,
      twos: null,
      twosIndex: 0,
      threes: null,
      menuMap: "about",
      scrollInfo: [],
      testList: null,
      screenInfo: null,
      isHtml: true,
    };
  },
  computed: {
    ...mapState(["styleH5", "setlangVal"]),
    getGameList() {
      const lang = ["zh", "hk"].includes(this.setlangVal) ? "zh" : "en";
      if (this.setlangVal) {
        this.games.forEach((item) => {
          item.bigImgVal = require("@/assets/img/langs/" +
            lang +
            "/games/" +
            item.bigImg);
        });
      }
      return this.games;
    },
    targetLangVal() {
      return this.langs.find((item) => item.type === this.setlangVal).name;
    },
    getmenu() {
      const list = [
        {
          name: this.$t("views.Index.523742-40"),
          link: "about",
        },
        {
          name: this.$t("views.Index.523742-3"),
          link: "superbw",
        },
        {
          name: this.$t("views.Index.523742-33"),
          link: "games",
        },
        {
          name: this.$t("views.Index.523742-36"),
          link: "cooperate",
        },
        {
          name: this.$t("views.Index.523742-38"),
          link: "Business",
        },
      ];
      return list;
    },
    getNav() {
      return [
        {
          name: this.$t("views.Index.523742-6"),
          type: "1",
        },
        {
          name: this.$t("views.Index.523742-11"),
          type: "2",
        },
        {
          name: this.$t("views.Index.523742-12"),
          type: "3",
        },
        {
          name: this.$t("views.Index.523742-13"),
          type: "4",
        },
        {
          name: this.$t("views.Index.523742-18"),
          type: "5",
        },
        {
          name: this.$t("views.Index.523742-31"),
          type: "6",
        },
        {
          name: this.$t("views.Index.523742-32"),
          type: "7",
        },
      ];
    },
    getGames() {
      return [
        {
          name: this.$t("views.Index.523742-42"),
          type: "",
        },
        {
          name: this.$t("views.Index.523742-43"),
          type: "chess",
        },
        {
          name: this.$t("views.Index.523742-44"),
          type: "electron",
        },
        {
          name: this.$t("views.Index.523742-45"),
          type: "fish",
        },
        {
          name: this.$t("views.Index.523742-46"),
          type: "blockchain",
        },
      ];
    },
    getFour() {
      const list = [
        {
          name: this.$t("data.fourLangs.999893-0"),
          icon: require("@/assets/img/cn.png"),
          lang: this.$t("data.fourLangs.999893-1"),
          sec: this.$t("data.fourLangs.999893-2"),
          number: this.$t("data.fourLangs.999893-3"),
        },
        {
          name: this.$t("data.fourLangs.999893-4"),
          icon: require("@/assets/img/lang.png"),
          lang: this.$t("data.fourLangs.999893-5"),
          sec: this.$t("data.fourLangs.999893-6"),
          number: this.$t("data.fourLangs.999893-7"),
        },
        {
          name: this.$t("data.fourLangs.999893-8"),
          icon: require("@/assets/img/cny.png"),
          lang: this.$t("data.fourLangs.999893-9"),
          sec: this.$t("data.fourLangs.999893-9-1"),
          number: this.$t("data.fourLangs.999893-10"),
        },
        {
          name: this.$t("data.fourLangs.999893-11"),
          icon: require("@/assets/img/yn.png"),
          lang: this.$t("data.fourLangs.999893-12"),
          sec: this.$t("data.fourLangs.999893-13"),
          number: this.$t("data.fourLangs.999893-14"),
        },
        {
          name: this.$t("data.fourLangs.999893-15"),
          icon: require("@/assets/img/xby.png"),
          lang: this.$t("data.fourLangs.999893-16"),
          sec: this.$t("data.fourLangs.999893-17"),
          number: this.$t("data.fourLangs.999893-18"),
        },
        {
          name: this.$t("data.fourLangs.999893-19"),
          icon: require("@/assets/img/fy.png"),
          lang: this.$t("data.fourLangs.999893-20"),
          sec: this.$t("data.fourLangs.999893-20-1"),
          number: this.$t("data.fourLangs.999893-21"),
        },
        {
          name: this.$t("data.fourLangs.999893-22"),
          icon: require("@/assets/img/alb.png"),
          lang: this.$t("data.fourLangs.999893-23"),
          sec: this.$t("data.fourLangs.999893-24"),
          number: this.$t("data.fourLangs.999893-25"),
        },
        {
          name: this.$t("data.fourLangs.999893-26"),
          icon: require("@/assets/img/els.png"),
          lang: this.$t("data.fourLangs.999893-27"),
          sec: this.$t("data.fourLangs.999893-28"),
          number: this.$t("data.fourLangs.999893-29"),
        },
        {
          name: this.$t("data.fourLangs.999893-30"),
          icon: require("@/assets/img/pty.png"),
          lang: this.$t("data.fourLangs.999893-31"),
          sec: this.$t("data.fourLangs.999893-31-1"),
          number: this.$t("data.fourLangs.999893-32"),
        },
        {
          name: this.$t("data.fourLangs.999893-33"),
          icon: require("@/assets/img/yny.png"),
          lang: this.$t("data.fourLangs.999893-34"),
          sec: this.$t("data.fourLangs.999893-35"),
          number: this.$t("data.fourLangs.999893-36"),
        },
        {
          name: this.$t("data.fourLangs.999893-37"),
          icon: require("@/assets/img/dy.png"),
          lang: this.$t("data.fourLangs.999893-38"),
          sec: this.$t("data.fourLangs.999893-38-1"),
          number: this.$t("data.fourLangs.999893-39"),
        },
        {
          name: this.$t("data.fourLangs.999893-40"),
          icon: require("@/assets/img/rb.png"),
          lang: this.$t("data.fourLangs.999893-41"),
          sec: this.$t("data.fourLangs.999893-42"),
          number: this.$t("data.fourLangs.999893-43"),
        },
        {
          name: this.$t("data.fourLangs.999893-44"),
          icon: require("@/assets/img/hg.png"),
          lang: this.$t("data.fourLangs.999893-45"),
          sec: this.$t("data.fourLangs.999893-46"),
          number: this.$t("data.fourLangs.999893-47"),
        },
        {
          name: this.$t("data.fourLangs.999893-48"),
          icon: require("@/assets/img/yy.png"),
          lang: this.$t("data.fourLangs.999893-49"),
          sec: this.$t("data.fourLangs.999893-50"),
          number: this.$t("data.fourLangs.999893-51"),
        },
        {
          name: this.$t("data.fourLangs.999893-52"),
          icon: require("@/assets/img/ydl.png"),
          lang: this.$t("data.fourLangs.999893-53"),
          sec: this.$t("data.fourLangs.999893-53-1"),
          number: this.$t("data.fourLangs.999893-54"),
        },
        {
          name: this.$t("data.fourLangs.999893-55"),
          icon: require("@/assets/img/ty.png"),
          lang: this.$t("data.fourLangs.999893-56"),
          sec: this.$t("data.fourLangs.999893-57"),
          number: this.$t("data.fourLangs.999893-58"),
        },
        {
          name: this.$t("data.fourLangs.999893-59"),
          icon: require("@/assets/img/md.png"),
          lang: this.$t("data.fourLangs.999893-60"),
          sec: this.$t("data.fourLangs.999893-61"),
          number: this.$t("data.fourLangs.999893-62"),
        },
      ];
      return list;
    },
  },
  watch: {
    showPop(val) {
      if (val) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    },
  },
  mounted() {
    new WOW.WOW().init();
    this.inifH5();
    window.addEventListener("resize", this.inifH5);
    this.initInfoLang();
    this.popOverBody();
    this.initGameList();
    this.$nextTick(() => {
      this.watchDom();
    });
    window.addEventListener("mousewheel", this.scrollInfoWacth);
    // this.noCode();
  },
  methods: {
    scrollInfoWacth() {
      this.screenInfo.forEach((item) => {
        const dom = document.getElementsByClassName(item.type)[0];
        const goto = dom.getBoundingClientRect();
        let goto80 = goto.top - 80;
        if (goto80 <= 0 && -goto80 <= goto.height) {
          this.menuMap = item.type;
        }
      });
    },
    initGameList() {
      const lang = ["zh", "hk"].includes(this.setlangVal) ? "zh" : "en";
      if (this.setlangVal) {
        this.games.forEach((item) => {
          item.bigImgVal = require("@/assets/img/langs/" +
            lang +
            "/games/" +
            item.bigImg);
        });
        this.testList = this.games;
      }
    },
    popOverBody() {
      if (this.showPop) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "";
      }
    },
    noCode() {
      // 禁止键盘F12
      document.addEventListener("keydown", function (e) {
        if (e.key == "F12") {
          e.preventDefault();
        }
      });

      // 禁止浏览器右键
      document.oncontextmenu = function (event) {
        if (window.event) {
          event = window.event;
        }
        try {
          var the = event.srcElement;
          if (
            !(
              (the.tagName == "INPUT" && the.type.toLowerCase() == "text") ||
              the.tagName == "TEXTAREA"
            )
          ) {
            return false;
          }
          return true;
        } catch (e) {
          return false;
        }
      };

      // 如果进入开发者模式设置debugger，跳空白页
      setInterval(function () {
        var startTime = performance.now();
        // 设置断点
        debugger;
        var endTime = performance.now();
        // 设置一个阈值，例如100毫秒
        if (endTime - startTime > 100) {
          window.location.href = "about:blank";
        }
      }, 100);
    },
    tabLange(val) {
      this.$store.dispatch("setlangValsAc", val.type);
      localStorage.setItem("lang", val.type);
      this.initInfoLang();
    },
    initInfoLang() {
      const langs = require("./data/langs/" + this.setlangVal + ".js");
      this.activiVal = langs.default;
      this.$i18n.locale = this.setlangVal;
      this.showPop = true;
      this.initGameList();

      // 产品图片
      let produces = [];
      const langlength = [
        { type: "zh", number: 10 },
        { type: "hk", number: 10 },
        { type: "en", number: 10 },
        { type: "tk", number: 10 },
        { type: "tk", number: 10 },
      ];

      const produceLength = langlength.find(
        (item) => item.type === this.setlangVal
      );
      for (let i = 1; i < produceLength.number; i++) {
        produces.push(
          require("@/assets/img/langs/" +
            this.setlangVal +
            "/banner01/" +
            i +
            ".png")
        );
      }
      this.twos = produces;

      // 活动图片
      let arrs = [];
      const langActivitylength = [
        { type: "zh", number: 10 },
        { type: "hk", number: 14 },
        { type: "en", number: 18 },
        { type: "tk", number: 10 },
        { type: "tk", number: 10 },
      ];

      const activityLength = langActivitylength.find(
        (item) => item.type === this.setlangVal
      );
      for (let i = 1; i < activityLength.number; i++) {
        arrs.push(
          require("@/assets/img/langs/" +
            this.setlangVal +
            "/setp/" +
            i +
            ".png")
        );
      }
      this.threes = arrs;
    },
    tabJump(val) {
      const domHei =
        document.documentElement.clientWidth || document.body.clientWidth;

      if (domHei < 1024) {
        let a = document.createElement("a");
        a.rel = "noreferrer";
        a.href = "#h5money";
        setTimeout(() => {
          a.click();
        }, 10);
      } else {
        this.navIndex = val;
      }
    },
    openH5() {
      if (this.styleH5) {
        this.$store.dispatch("setStyleH5Ac", false);
      } else {
        this.$store.dispatch("setStyleH5Ac", true);
      }
    },
    inifH5() {
      const domHei =
        document.documentElement.clientWidth || document.body.clientWidth;
      if (domHei < 1024) {
        this.$store.dispatch("setStyleH5Ac", false);
      } else {
        this.$store.dispatch("setStyleH5Ac", true);
      }
      const screenWidth = screen.width;
      const widnowWidth = window.innerWidth;
      console.log(screenWidth, widnowWidth);
      // if (screenWidth !== widnowWidth) {
      //   this.isHtml = false;
      // } else {
      //   this.isHtml = true;
      // }
    },
    watchDom() {
      const domHei =
        document.documentElement.clientHeight || document.body.clientHeight;

      const list = [];
      this.getmenu.forEach((item) => {
        const danGe = document.getElementsByClassName(item.link)[0];
        const info = danGe.offsetTop;
        list.push({
          type: item.link,
          top: info,
          height: danGe.offsetHeight,
        });
      });
      this.screenInfo = list;
      console.log(this.screenInfo);
    },
    jumpMao(val) {
      const domHei =
        document.documentElement.clientWidth || document.body.clientWidth;
      if (domHei < 1024) {
        this.openH5();
      }
      // 计算元素距离顶部的距离
      const dom = document.getElementsByClassName(val.link)[0];
      const rect = dom.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const elementTop = rect.top + scrollTop;

      // 滚动到该元素的位置
      window.scrollTo({
        top: elementTop - 80,
        behavior: "smooth",
      });
      this.menuMap = val.link;
    },
    filterTag(value, row) {
      return row.nameType === value;
    },
    // 第五个数据整理
    // fiveLists() {
    //   let gameTypeArr = [];
    //   this.fives.forEach((item) => {
    //     let arr = [];
    //     gameTypeArr.push({
    //       text: item.name,
    //       value: item.type,
    //     });

    //     item.platy.forEach((row) => {
    //       row.list.forEach((ite) => {
    //         let Obj = {
    //           name: item.name,
    //           nameType: item.type,
    //           platyname: row.name,
    //           platyType: row.type,
    //           current: ite.name,
    //           currentType: ite.type,
    //           bl: ite.load,
    //         };
    //         arr.push(Obj);
    //       });
    //     });

    //     this.fiveList.push(...arr);
    //   });
    //   this.gameTypes = gameTypeArr;
    // },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        // 获取当前单元格的值
        const currentValue = row.nameType;
        // 获取上一行相同列的值
        const preRow = this.fiveLists[rowIndex - 1];
        const preValue = preRow ? preRow.nameType : null;
        // 如果当前值和上一行的值相同，则将当前单元格隐藏
        if (currentValue === preValue) {
          return { rowspan: 0, colspan: 0 };
        } else {
          // 否则计算当前单元格应该跨越多少行
          let rowspan = 1;
          for (let i = rowIndex + 1; i < this.fiveLists.length; i++) {
            const nextRow = this.fiveLists[i];
            const nextValue = nextRow.nameType;
            if (nextValue === currentValue) {
              rowspan++;
            } else {
              break;
            }
          }
          return { rowspan, colspan: 1 };
        }
      }
      if (columnIndex === 1) {
        // 获取当前单元格的值
        const currentValue = row.platyType;
        // 获取上一行相同列的值
        const preRow = this.fiveLists[rowIndex - 1];
        const preValue = preRow ? preRow.platyType : null;
        // 如果当前值和上一行的值相同，则将当前单元格隐藏
        if (currentValue === preValue) {
          return { rowspan: 0, colspan: 0 };
        } else {
          // 否则计算当前单元格应该跨越多少行
          let rowspan = 1;
          for (let i = rowIndex + 1; i < this.fiveLists.length; i++) {
            const nextRow = this.fiveLists[i];
            const nextValue = nextRow.platyType;
            if (nextValue === currentValue) {
              rowspan++;
            } else {
              break;
            }
          }
          return { rowspan, colspan: 1 };
        }
      }
    },
    // tabBanner(val) {
    //   const domWid = this.$refs.banner[0].clientWidth + 10;
    //   if (val === "prve") {
    //     // 上一步
    //     if (this.twosIndex === 0) return;
    //     this.twosIndex--;
    //     this.bannerVal = -(domWid * this.twosIndex);
    //   } else {
    //     // 下一步
    //     if (this.twosIndex >= this.twos.length - 4) return;
    //     this.twosIndex++;
    //     this.bannerVal = -(domWid * this.twosIndex);
    //   }
    // },
    // 游戏切换
    openGameType(val, index) {
      this.gameIndex = index;
      if (val.type) {
        this.testList = this.getGameList.filter(
          (item) => item.type === val.type
        );
      } else {
        this.testList = this.getGameList;
      }
    },
  },
  destroyed() {
    window.removeEventListener("mousewheel", this.scrollInfoWacth);
    window.removeEventListener("resize", this.inifH5);
  },
};
</script>
<style lang="scss" scoped>
@import "wowjs/css/libs/animate.css";
.wg-header {
  z-index: 30;
  box-shadow: 0 0.02rem 0.06rem rgba(0, 0, 0, 0.1);
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .left {
    display: flex;
    align-items: center;
    gap: 0.32rem;
    .login {
      background-image: url("@/assets/img/mini.png");
      background-position: 0 0;
      background-size: 2.82rem 1.96667rem;
      color: #fff;
      cursor: pointer;
      font-size: 0.2rem;
      font-style: italic;
      font-weight: 700;
      height: 0.60333rem;
      width: 1.98rem;
    }
    .slogin {
      background-image: url("@/assets/img/mini.png");
      background-position: -4.24rem -2.32rem;
      background-size: 8.46rem 5.9rem;
      height: 0.4rem;
      width: 1.21rem;
      position: relative;
      &::after {
        animation: streamer 1s both infinite;
        background-color: #fff;
        border-radius: 0.02rem;
        box-shadow: 0 0 0.01rem transparent;
        content: "";
        height: 100%;
        opacity: 0.4;
        position: absolute;
        width: 0.15rem;
      }
      &.tv {
        background-position: -4.876rem -2.1045rem;
        background-size: 9.729rem 6.785rem;
        height: 0.5405rem;
        width: 1.84rem;
      }
      &.tk {
        background-position: -6.854rem -2.5875rem;
        background-size: 9.729rem 6.785rem;
        height: 0.6555rem;
        width: 1.932rem;
      }
      &.en {
        background-position: -8.027rem -5.4625rem;
        background-size: 9.729rem 6.785rem;
        height: 0.4945rem;
        width: 1.5985rem;
      }
      &.hk {
        background-position: -5.359rem -3.2775rem;
        background-size: 9.729rem 6.785rem;
        height: 0.46rem;
        width: 1.3915rem;
      }
    }
  }
  .center {
    ul {
      display: flex;
      align-items: center;
      li {
        cursor: pointer;
        min-width: 0.8rem;
        height: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 0.12rem;
        font-size: 0.18rem;
        &.hover {
          color: white;
          background: #0c64ea;
          a {
            color: white;
          }
        }
        a {
          color: #333;
          text-decoration: none;
        }
      }
    }
  }
  .right {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    .bw-h5-menus {
      display: none;
    }
    .wg-lang {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.08rem;
      border: 0.01rem solid #e5e5e5;
      border-radius: 0.04rem;
      color: #333 !important;
      cursor: pointer;
      font-size: 0.18rem !important;
      padding: 0.06rem 0.08rem;
      white-space: nowrap;
      .arrow {
        position: relative;
        width: 0.1rem;
        height: 0.1rem;
        transition: all 0.4s;
        display: block;
        &::before {
          content: "";
          width: 0.08rem;
          height: 0.08rem;
          display: block;
          border: 1px solid transparent;
          border-bottom-color: #0c64ea;
          border-left-color: #0c64ea;
          transform-origin: center center;
          transform: translate(-50%, -80%) rotate(-45deg);
          top: 50%;
          left: 50%;
          position: absolute;
        }
        &.xz {
          transform: rotate(-180deg);
        }
      }
    }
    .wg-langs {
      background-color: #fff;
      border: 1px solid #ebeef5;
      border-radius: 4px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      right: 0;
      padding: 10px 0;
      position: absolute;
      top: 0.6rem;
      z-index: 10;
      li {
        align-items: center;
        display: flex;
        font-size: 0.18rem;
        height: 0.6rem;
        padding: 0 0.15rem;
        white-space: nowrap;
        width: 1.4rem;
        cursor: pointer;
        &.hover {
          background-color: #ecf5ff;
          color: #0c64ea;
        }
      }
    }
  }
}

@keyframes streamer {
  0% {
    left: 0;
  }

  to {
    left: 100%;
  }
}
.wg-banner {
  display: flex;
  height: 26.0416666667vw;
  justify-content: center;
  margin-bottom: 0.3rem;
  max-height: 5rem;
  position: relative;
  text-align: center;
  .isH5 {
    display: none;
  }
  &-text {
    font-size: 0.19rem;
    line-height: 0.24rem;
    max-width: 100%;
    text-align: left;
    width: 11.52rem;
    bottom: 0.1rem;
    color: #fff;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
  }
}
// / 元素开始进入的状态|元素离开结束的状态
.run-enter-active,
.run-leave-active {
  transition: all 1s;
}
.run-enter, .run-leave-to /* .fade-leave-active for <=2.1.8 */ {
  opacity: 0;
  transform: scale(1.1);
  filter: blur(20px);
}
.h5menu-enter-active,
.h5menu-leave-active {
  transition: all 1s;
}
.h5menu-enter, .h5menu-leave-to /* .fade-leave-active for <=2.1.8 */ {
  opacity: 0;
}
</style>
