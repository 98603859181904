export default {
    computed: {
        fiveList() {
            return [{
                    name: this.$t('data.five.966565-0'),
                    type: 'live',
                    platy: [{
                            type: 'AE',
                            name: 'AE',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '10%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '10%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '10%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '10%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '10%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '10%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '10%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '10%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '10%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '10%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '10%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '10%',
                            }]
                        },
                        {
                            type: 'AG',
                            name: 'AG',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '10%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '10%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '10%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '10%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '10%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '10%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '10%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '10%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '10%',
                            }]
                        },
                        {
                            type: 'BBIN',
                            name: 'BBIN',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '10%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '10%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '10%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '10%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '10%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '10%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '10%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '10%',
                            }]
                        },
                        {
                            type: 'Beterlive',
                            name: 'Beterlive',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '14%',
                            }, {
                                type: 'MAD',
                                name: `MAD${this.$t('data.five.966565-14')}`,
                                load: '14%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '14%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '14%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '14%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '14%',
                            }]
                        },
                        {
                            type: 'BG',
                            name: 'BG',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '10%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '10%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '10%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '10%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '10%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '10%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '10%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '10%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '10%',
                            }]
                        },
                        {
                            type: 'CQ9',
                            name: 'CQ9',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '10%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '10%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '10%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '10%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '10%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '10%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '10%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '10%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '10%',
                            }]
                        },
                        {
                            type: 'DB',
                            name: 'DB',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '10%',
                            }]
                        },
                        {
                            type: 'DG',
                            name: 'DG',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '10%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '10%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '10%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '10%',
                            }]
                        },
                        {
                            type: 'EVO',
                            name: 'EVO',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '15%',
                            }, {
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '15%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '15%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '15%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '12%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '15%',
                            }, {
                                type: 'MAD',
                                name: `MAD${this.$t('data.five.966565-14')}`,
                                load: '15%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '15%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '15%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '15%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '15%',
                            }, {
                                type: 'PYG',
                                name: `PYG${this.$t('data.five.966565-18')}`,
                                load: '15%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '15%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '15%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '15%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '15%',
                            }]
                        },
                        {
                            type: 'Ezugi',
                            name: 'Ezugi',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '10%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '10%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '10%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '10%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '10%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '10%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '10%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '10%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '10%',
                            }, {
                                type: 'PYG',
                                name: `PYG${this.$t('data.five.966565-18')}`,
                                load: '10%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '10%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '10%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '10%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '10%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '10%',
                            }]
                        },
                        {
                            type: 'Playtech',
                            name: 'Playtech',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '16%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '16%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '16%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '16%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '16%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '16%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '16%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '16%',
                            }]
                        },
                        {
                            type: 'PP',
                            name: 'PP',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '13%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '13%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '13%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '13%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '13%',
                            }, {
                                type: 'MAD',
                                name: `MAD${this.$t('data.five.966565-14')}`,
                                load: '13%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '13%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '13%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '13%',
                            }, {
                                type: 'PYG',
                                name: `PYG${this.$t('data.five.966565-18')}`,
                                load: '13%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '13%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '13%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '13%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '13%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '13%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '13%',
                            }]
                        },
                        {
                            type: 'SA',
                            name: 'SA',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '10%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '10%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '10%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '10%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '10%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '10%',
                            }, {
                                type: 'MAD',
                                name: `MAD${this.$t('data.five.966565-14')}`,
                                load: '13%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '10%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '10%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '10%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '10%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '10%',
                            }]
                        },
                        {
                            type: 'TP',
                            name: 'TP',
                            list: [{
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '10%',
                            }]
                        },
                        {
                            type: 'WE',
                            name: 'WE',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '10%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '10%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '10%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '10%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '10%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '10%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '10%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '10%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '10%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '10%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '10%',
                            }]
                        },
                        {
                            type: 'WL',
                            name: 'WL',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '10%',
                            }]
                        },
                        {
                            type: 'WM',
                            name: 'WM',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '10%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '10%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '10%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '10%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '10%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '10%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '10%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '10%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '10%',
                            }]
                        }
                    ]
                },
                {
                    name: this.$t('data.five.966565-19'),
                    type: 'egame',
                    platy: [{
                            type: 'Baison',
                            name: 'Baison',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }]
                        },
                        {
                            type: 'BBIN',
                            name: 'BBIN',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '9%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }]
                        },
                        {
                            type: 'BNG',
                            name: 'BNG',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '15%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '15%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '15%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '15%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '15%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '15%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '15%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '15%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '15%',
                            }]
                        },
                        {
                            type: 'BT',
                            name: 'BT GAMING',
                            list: [{
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }]
                        },
                        {
                            type: 'BIG',
                            name: 'BIG TIME GAMING',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '9%',
                            }, {
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '13%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '9%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '13%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '10%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '10%',
                            }, {
                                type: 'PYG',
                                name: `PYG${this.$t('data.five.966565-18')}`,
                                load: '13%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '13%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '13%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }]
                        },
                        {
                            type: 'CG',
                            name: 'CREATIVE GAMING',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '9%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '9%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '9%',
                            }, {
                                type: 'PYG',
                                name: `PYG${this.$t('data.five.966565-18')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '9%',
                            }]
                        },
                        {
                            type: 'CQ9',
                            name: 'CQ9',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '9%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '9%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }]
                        },
                        {
                            type: 'Dragoon',
                            name: 'Dragoon Soft',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '9%',
                            }, {
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '9%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '9%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '9%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '9%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }]
                        },
                        {
                            type: 'ELK',
                            name: 'ELK',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '14%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '14%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '14%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '14%',
                            }]
                        },
                        {
                            type: 'Evoplay',
                            name: 'Evoplay',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '14%',
                            }, {
                                type: 'MAD',
                                name: `MAD${this.$t('data.five.966565-14')}`,
                                load: '14%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '14%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '14%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '14%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '14%',
                            }]
                        },
                        {
                            type: 'FA',
                            name: 'FA CHAI Gaming',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '9%',
                            }, {
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '9%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '9%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '9%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '9%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '9%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }]
                        },
                        {
                            type: 'Fantasma',
                            name: 'Fantasma Gaming',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '15%',
                            }, {
                                type: 'MAD',
                                name: `MAD${this.$t('data.five.966565-14')}`,
                                load: '15%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '15%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '15%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '15%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '15%',
                            }]
                        },
                        {
                            type: 'FunTa',
                            name: 'FunTa',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '9%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '9%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }]
                        },
                        {
                            type: 'FUN',
                            name: 'Fun Gaming',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '9%',
                            }]
                        },
                        {
                            type: 'GoodFortune',
                            name: 'GoodFortune Gaming',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '9%',
                            }, {
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '9%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '9%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '9%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '9%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'Habanero',
                            name: 'Habanero',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '9%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '9%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '9%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '9%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'Hacksaw',
                            name: 'Hacksaw Gaming',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '9%',
                            }, {
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '14%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '9%',
                            }, {
                                type: 'MAD',
                                name: `MAD${this.$t('data.five.966565-14')}`,
                                load: '14%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '14%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '14%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'NGN',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '14%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '14%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'JDB',
                            name: 'JDB',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '9%',
                            }, {
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '9%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '9%',
                            }, {
                                type: 'MAD',
                                name: `MAD${this.$t('data.five.966565-14')}`,
                                load: '9%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '9%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '9%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'NGN',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '9%',
                            }, {
                                type: 'PYG',
                                name: `PYG${this.$t('data.five.966565-18')}`,
                                load: '9%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '16%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'JILI',
                            name: 'JILI',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '9%',
                            }, {
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '9%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '9%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'NGN',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'JOKER',
                            name: 'JOKER',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '9%',
                            }, {
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '9%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '9%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '9%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '11%',
                            }, ]
                        },
                        {
                            type: 'KA',
                            name: 'KA',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '9%',
                            }, {
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '9%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '9%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '9%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '11%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '11%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '11%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'KX',
                            name: 'KX',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'Micro',
                            name: 'Micro Gaming',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'LEG',
                            name: 'LEG',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '11%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '13%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '11%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '11%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '11%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '11%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '11%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '11%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '11%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '11%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '11%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '11%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '11%',
                            }, ]
                        },
                        {
                            type: 'Manna',
                            name: 'Manna Play',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '12%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '12%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '12%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '12%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '12%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '13%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '12%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '12%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '13%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '12%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '12%',
                            }, ]
                        },
                        {
                            type: 'MP',
                            name: 'Millionaire Poker',
                            list: [{
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '11%',
                            }]
                        },
                        {
                            type: 'NetEnt',
                            name: 'NetEnt',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '15%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'MAD',
                                name: `MAD${this.$t('data.five.966565-14')}`,
                                load: '15%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '15%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '15%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '9%',
                            }, {
                                type: 'PYG',
                                name: `PYG${this.$t('data.five.966565-18')}`,
                                load: '15%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '13%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '13%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'NLC',
                            name: 'NLC',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '13%',
                            }, {
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '13%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '13%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '13%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '13%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '13%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '15%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '15%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '13%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '13%',
                            }, {
                                type: 'PYG',
                                name: `PYG${this.$t('data.five.966565-18')}`,
                                load: '13%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '13%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '13%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '13%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '13%',
                            }, ]
                        },
                        {
                            type: 'PG',
                            name: 'PG',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '9%',
                            }, {
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '9%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '9%',
                            }, {
                                type: 'MAD',
                                name: `MAD${this.$t('data.five.966565-14')}`,
                                load: '9%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '9%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '9%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '9%',
                            }, {
                                type: 'PYG',
                                name: `PYG${this.$t('data.five.966565-18')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '10%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'DB',
                            name: 'DB SLOTS',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '9%',
                            }, {
                                type: 'PYG',
                                name: `PYG${this.$t('data.five.966565-18')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'Play',
                            name: 'Play‘n GO',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '17%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '17%',
                            }, {
                                type: 'MAD',
                                name: `MAD${this.$t('data.five.966565-14')}`,
                                load: '17%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '17%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '17%',
                            }, {
                                type: 'PYG',
                                name: `PYG${this.$t('data.five.966565-18')}`,
                                load: '17%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '13%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '13%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '13%',
                            }, ]
                        },
                        {
                            type: 'Playtech',
                            name: 'Playtech',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '16%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '14%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '14%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '17%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '14%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '16%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '16%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '14%',
                            }, ]
                        },
                        {
                            type: 'PP',
                            name: 'PP',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '11%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '11%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '11%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '11%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '11%',
                            }, {
                                type: 'MAD',
                                name: `MAD${this.$t('data.five.966565-14')}`,
                                load: '14%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '14%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '14%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '11%',
                            }, {
                                type: 'PYG',
                                name: `PYG${this.$t('data.five.966565-18')}`,
                                load: '14%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '14%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '11%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '11%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '14%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '11%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '11%',
                            }, ]
                        },
                        {
                            type: 'PS',
                            name: 'PS',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '11%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '11%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '11%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '10%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '10%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '11%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '11%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '11%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'PUSH',
                            name: 'PUSH GAMING',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '15%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '15%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '15%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '15%',
                            }, ]
                        },
                        {
                            type: 'Redtiger',
                            name: 'Redtiger',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '9%',
                            }, {
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '15%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '9%',
                            }, {
                                type: 'MAD',
                                name: `MAD${this.$t('data.five.966565-14')}`,
                                load: '15%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '15%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '15%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '15%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'RICH88',
                            name: 'RICH88',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '9%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '9%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '15%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        }, {
                            type: 'Relax',
                            name: 'Relax Gaming',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '14%',
                            }, {
                                type: 'MAD',
                                name: `MAD${this.$t('data.five.966565-14')}`,
                                load: '14%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '14%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '14%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '14%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '14%',
                            }, ]
                        },
                        {
                            type: 'RSG',
                            name: 'RSG',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '9%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'SBO',
                            name: 'SBO',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '13%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '13%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '13%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '13%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '13%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '13%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '13%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '13%',
                            }, {
                                type: 'PYG',
                                name: `PYG${this.$t('data.five.966565-18')}`,
                                load: '13%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '13%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '13%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '13%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '13%',
                            }, ]
                        },
                        {
                            type: 'Spade',
                            name: 'Spade Gaming',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '9%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '9%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '9%',
                            }, {
                                type: 'RUB',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'Slotmill',
                            name: 'Slotmill',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '15%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '15%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '15%',
                            }, {
                                type: 'PYG',
                                name: `PYG${this.$t('data.five.966565-18')}`,
                                load: '15%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '15%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '15%',
                            }, ]
                        },
                        {
                            type: 'TADA',
                            name: 'TADA',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '9%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '9%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'Thunderkick',
                            name: 'Thunderkick',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '15%',
                            }, {
                                type: 'MAD',
                                name: `MAD${this.$t('data.five.966565-14')}`,
                                load: '15%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '15%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '15%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '15%',
                            }, ]
                        },
                        {
                            type: 'TP',
                            name: 'TP',
                            list: [{
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'V8',
                            name: 'V8',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '11%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '11%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '11%',
                            }, ]
                        },
                        {
                            type: 'Wazdan',
                            name: 'Wazdan',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '13%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '13%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '13%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '13%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '13%',
                            }, ]
                        },
                        {
                            type: 'WG',
                            name: 'WG',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '5%',
                            }, {
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '5%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '5%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '5%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '5%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '5%',
                            }, {
                                type: 'KHR',
                                name: `KHR${this.$t('data.five.966565-20')}`,
                                load: '5%',
                            }, {
                                type: 'KRW',
                                name: `KRW${this.$t('data.five.966565-21')}`,
                                load: '5%',
                            }, {
                                type: 'PYG',
                                name: `PYG${this.$t('data.five.966565-18')}`,
                                load: '5%',
                            }, {
                                type: 'MAD',
                                name: `MAD${this.$t('data.five.966565-14')}`,
                                load: '5%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '5%',
                            }, {
                                type: 'NGN',
                                name: `NGN${this.$t('data.five.966565-16')}`,
                                load: '5%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '5%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '5%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '5%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '5%',
                            }, ]
                        },
                        {
                            type: 'WL',
                            name: 'WL',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'AG',
                            name: 'XIN',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '11%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '11%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '11%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '11%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '11%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '11%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '11%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '11%',
                            }, ]
                        },
                        {
                            type: 'YESBINGO',
                            name: 'YESBINGO',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '11%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '11%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '11%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '11%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '11%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '11%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '11%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '11%',
                            }, {
                                type: 'PKR',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '11%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '11%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '11%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '11%',
                            }, ]
                        },
                        {
                            type: 'YGG',
                            name: 'YGG',
                            list: [{
                                type: 'BDT',
                                name: `BDT${this.$t('data.five.966565-1')}`,
                                load: '11%',
                            }, {
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '15%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '11%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '11%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '11%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '11%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '11%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '11%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '11%',
                            }, {
                                type: 'PKR',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '11%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '11%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '11%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '11%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '15%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '11%',
                            }, ]
                        },
                        {
                            type: 'YGR',
                            name: 'YGR',
                            list: [{
                                type: 'BRL',
                                name: `BRL${this.$t('data.five.966565-13')}`,
                                load: '9%',
                            }, {
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '9%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '9%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '9%',
                            }, {
                                type: 'MXN',
                                name: `MXN${this.$t('data.five.966565-15')}`,
                                load: '9%',
                            }, {
                                type: 'PHP',
                                name: `PHP${this.$t('data.five.966565-6')}`,
                                load: '9%',
                            }, {
                                type: 'PKR',
                                name: `PKR${this.$t('data.five.966565-7')}`,
                                load: '9%',
                            }, {
                                type: 'PKR',
                                name: `RUB${this.$t('data.five.966565-8')}`,
                                load: '9%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '9%',
                            }, {
                                type: 'TRX',
                                name: `TRX${this.$t('data.five.966565-17')}`,
                                load: '9%',
                            }, {
                                type: 'TRY',
                                name: `BRL${this.$t('data.five.966565-10')}`,
                                load: '9%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '9%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '9%',
                            }, ]
                        },
                        {
                            type: 'AG',
                            name: 'YOPLAY',
                            list: [{
                                type: 'CNY',
                                name: `CNY${this.$t('data.five.966565-2')}`,
                                load: '11%',
                            }, {
                                type: 'IDR',
                                name: `IDR${this.$t('data.five.966565-3')}`,
                                load: '11%',
                            }, {
                                type: 'INR',
                                name: `INR${this.$t('data.five.966565-4')}`,
                                load: '11%',
                            }, {
                                type: 'JPY',
                                name: `JPY${this.$t('data.five.966565-5')}`,
                                load: '11%',
                            }, {
                                type: 'THB',
                                name: `THB${this.$t('data.five.966565-9')}`,
                                load: '11%',
                            }, {
                                type: 'USDT',
                                name: `USDT${this.$t('data.five.966565-11')}`,
                                load: '11%',
                            }, {
                                type: 'VND',
                                name: `VND${this.$t('data.five.966565-12')}`,
                                load: '11%',
                            }, ]
                        }
                    ]
                },
                {
                    name: this.$t('data.five.966565-22'),
                    type: 'Chess',
                    platy: [{
                        type: '365',
                        name: '365',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'Baison',
                        name: 'Baison',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MAD${this.$t('data.five.966565-14')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'PKR',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'BT',
                        name: 'BT GAMING',
                        list: [{
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'CG',
                        name: 'CREATIVE GAMING',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '9%',
                        }, {
                            type: 'PYG',
                            name: `PYG${this.$t('data.five.966565-18')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'DBLIVE',
                        name: 'DB POKER',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '10%',
                        }]
                    }, {
                        type: 'FUN',
                        name: 'Fun Gaming',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '10%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '10%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '10%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '10%',
                        }]
                    }, {
                        type: 'FunTa',
                        name: 'FunTa',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '10%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '10%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '10%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '10%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '10%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '10%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '10%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '10%',
                        }]
                    }, {
                        type: 'GoodFortune',
                        name: 'GoodFortune Gaming',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '9%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'PKR',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'GoodFortune',
                        name: 'GoodFortune Gaming',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '9%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'PKR',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'Habanero',
                        name: 'Habanero',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '10%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '10%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '10%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '10%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '10%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '10%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '10%',
                        }, {
                            type: 'PKR',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '10%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '10%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '10%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '10%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '10%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '10%',
                        }, ]
                    }, {
                        type: 'JDB',
                        name: 'JDB',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '9%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'MAD',
                            name: `MAD${this.$t('data.five.966565-14')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '9%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'JILI',
                        name: 'JILI',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '9%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'KM',
                        name: 'King Midas',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '10%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '10%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '10%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '10%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '10%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '10%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '10%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '10%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '10%',
                        }, ]
                    }, {
                        type: 'KX',
                        name: 'KX',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'KY',
                        name: 'KY',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'LEG',
                        name: 'LEG',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'Micro',
                        name: 'Micro Gaming',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '10.5%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '10.5%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '10.5%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '10.5%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '10.5%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '10.5%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '10.5%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '10.5%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '10.5%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '10.5%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '10.5%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'MP',
                        name: 'Millionaire Poker',
                        list: [{
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '11%',
                        }]
                    }, {
                        type: 'NW',
                        name: 'Millionaire Poker',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'Play',
                        name: 'Play‘n GO ',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '17%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '13%',
                        }, {
                            type: 'MAD',
                            name: `MAD${this.$t('data.five.966565-14')}`,
                            load: '17%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '17%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '17%',
                        }, {
                            type: 'PYG',
                            name: `PYG${this.$t('data.five.966565-18')}`,
                            load: '17%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '13%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '13%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '17%',
                        }, ]
                    }, {
                        type: 'PS',
                        name: 'PS',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '11%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '11%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '11%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'RICH88',
                        name: 'RICH88',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'Spade',
                        name: 'Spade Gaming',
                        list: [{
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '10%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '10%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '10%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '10%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '10%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '10%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '10%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '10%',
                        }, ]
                    }, {
                        type: 'Spade',
                        name: 'SGwin',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '10%',
                        }, ]
                    }, {
                        type: 'TADA',
                        name: 'TADA',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '10%',
                        }]
                    }, {
                        type: 'TPF',
                        name: 'TPF',
                        list: [{
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'V8',
                        name: 'V8',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '11%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '11%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '11%',
                        }]
                    }, {
                        type: 'WG',
                        name: 'WG',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '5%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '5%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '5%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '5%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '5%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '5%',
                        }, {
                            type: 'KHR',
                            name: `KHR${this.$t('data.five.966565-20')}`,
                            load: '5%',
                        }, {
                            type: 'KRW',
                            name: `KRW${this.$t('data.five.966565-21')}`,
                            load: '5%',
                        }, {
                            type: 'MAD',
                            name: `MAD${this.$t('data.five.966565-14')}`,
                            load: '5%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '5%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '5%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '5%',
                        }, {
                            type: 'PYG',
                            name: `PYG${this.$t('data.five.966565-18')}`,
                            load: '5%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '5%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '5%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '5%',
                        }, ]
                    }, {
                        type: 'WL',
                        name: 'WL',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }]
                    }]
                }, {
                    name: this.$t('data.five.966565-23'),
                    type: 'fish',
                    platy: [{
                        type: 'AG',
                        name: 'AG',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '10%',
                        }]
                    }, {
                        type: 'Baison',
                        name: 'Baison',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'MAD',
                            name: `MAD${this.$t('data.five.966565-14')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'BBIN',
                        name: 'BBIN',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'BG',
                        name: 'BG',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'BT',
                        name: 'BT GAMING',
                        list: [{
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'CQ9',
                        name: 'CQ9',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'Dragoon',
                        name: 'Dragoon Soft',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '9%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '9%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'FA',
                        name: 'FA CHAI Gaming',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '10%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '10%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '10%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '10%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '10%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '10%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '10%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '10%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '10%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '10%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '10%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '10%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '10%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '10%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '10%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '10%',
                        }, ]
                    }, {
                        type: 'FUN',
                        name: 'Fun Gaming',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '10%',
                        }]
                    }, {
                        type: 'GoodFortune',
                        name: 'GoodFortune Gaming',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '9%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'JDB',
                        name: 'JDB',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '9%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'MAD',
                            name: `MAD${this.$t('data.five.966565-14')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '9%',
                        }, {
                            type: 'PYG',
                            name: `PYG${this.$t('data.five.966565-18')}`,
                            load: '9%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '16%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'JILI',
                        name: 'JILI',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '9%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'JOKER',
                        name: 'JOKER',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '11%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '11%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '11%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '11%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '11%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '11%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '11%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '11%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'KA',
                        name: 'KA',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '11%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '11%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '11%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'KX',
                        name: 'KX',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'KY',
                        name: 'KY',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'LEG',
                        name: 'LEG',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'Micro',
                        name: 'Micro Gaming',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '9%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'NW',
                        name: 'NW',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'PS',
                        name: 'PS',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '11%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '11%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '11%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '11%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '11%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'RICH88',
                        name: 'RICH88',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'RSG',
                        name: 'RSG',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'Spade',
                        name: 'Spade Gaming',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '12%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '10%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '10%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '10%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'TADA',
                        name: 'TADA',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'TP',
                        name: 'TP',
                        list: [{
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'V8',
                        name: 'V8',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '11%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '11%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '11%',
                        }]
                    }, {
                        type: 'WG',
                        name: 'WG',
                        list: [{
                            type: 'BRL',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '5%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '5%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '5%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '5%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '5%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '5%',
                        }, {
                            type: 'JPY',
                            name: `KHR${this.$t('data.five.966565-20')}`,
                            load: '5%',
                        }, {
                            type: 'JPY',
                            name: `KRW${this.$t('data.five.966565-21')}`,
                            load: '5%',
                        }, {
                            type: 'MAD',
                            name: `MAD${this.$t('data.five.966565-14')}`,
                            load: '5%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '5%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '5%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '5%',
                        }, {
                            type: 'PYG',
                            name: `PYG${this.$t('data.five.966565-18')}`,
                            load: '5%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '5%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '5%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '5%',
                        }]
                    }, {
                        type: 'WL',
                        name: 'WL',
                        list: [{
                            type: 'WL',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'YESBINGO',
                        name: 'YESBINGO',
                        list: [{
                            type: 'BRL',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '11%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '11%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '11%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '11%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '11%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '11%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '11%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '11%',
                        }, {
                            type: 'PYG',
                            name: `PYG${this.$t('data.five.966565-18')}`,
                            load: '11%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '11%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '11%',
                        }]
                    }, {
                        type: 'YGR',
                        name: 'YGR',
                        list: [{
                            type: 'BRL',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '11%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '11%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '11%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '11%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '11%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '11%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '11%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '11%',
                        }, {
                            type: 'PYG',
                            name: `PYG${this.$t('data.five.966565-18')}`,
                            load: '11%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '11%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '11%',
                        }]
                    }]
                }, {
                    name: this.$t('data.five.966565-24'),
                    type: 'sports',
                    platy: [{
                        type: '3SING',
                        name: '3 SING',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '12%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '12%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '12%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '12%',
                        }, {
                            type: 'MXN',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '12%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '12%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '12%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '12%',
                        }]
                    }, {
                        type: '9wicket',
                        name: '9 wicket',
                        list: [{
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '16%',
                        }, {
                            type: 'MXN',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '17%',
                        }]
                    }, {
                        type: 'AEcricEX',
                        name: 'AEcricEX',
                        list: [{
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '17%',
                        }]
                    }, {
                        type: 'BBIN',
                        name: 'BBIN',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'BTI',
                        name: 'BTI',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '13%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '13%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '13%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '13%',
                        }, {
                            type: 'MAD',
                            name: `MAD${this.$t('data.five.966565-14')}`,
                            load: '13%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '13%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '13%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '13%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '13%',
                        }, {
                            type: 'MXN',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '13%',
                        }, {
                            type: 'PYG',
                            name: `PYG${this.$t('data.five.966565-18')}`,
                            load: '13%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '13%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '13%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '13%',
                        }]
                    }, {
                        type: 'CMD',
                        name: 'CMD',
                        list: [{
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '14%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '14%',
                        }]
                    }, {
                        type: 'CROWN',
                        name: 'CROWN',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '16%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '16%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '16%',
                        }, {
                            type: 'MAD',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '16%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '16%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '16%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '16%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '16%',
                        }]
                    }, {
                        type: 'FB',
                        name: 'FB',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '11%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '11%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '11%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '11%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '11%',
                        }, {
                            type: 'MAD',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '11%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '11%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '11%',
                        }]
                    }, {
                        type: 'IM',
                        name: 'IM',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '13%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '13%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '13%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '15%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '13%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '15%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '13%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '13%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '13%',
                        }]
                    }, {
                        type: 'Lucky',
                        name: 'Lucky',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '13%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '15%',
                        }]
                    }, {
                        type: 'AP',
                        name: 'AP GAMING',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '15%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '15%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '15%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '15%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '15%',
                        }]
                    }, {
                        type: 'PANDA',
                        name: 'PANDA SPORTS',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '13%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '13%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '13%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '13%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '13%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '13%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '13%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '13%',
                        }]
                    }, {
                        type: 'POLY',
                        name: 'POLY',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '15%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '13%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '13%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '13%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '13%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '13%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '13%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '13%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '13%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '13%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '13%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '15%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '13%',
                        }]
                    }, {
                        type: 'SABA',
                        name: 'SABA',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '12%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '12%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '12%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '12%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '12%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '12%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '12%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '12%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '12%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '12%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '12%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '12%',
                        }]
                    }, {
                        type: 'SBO',
                        name: 'SBO',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '16%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '12%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '16%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '16%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '16%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '16%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '16%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '16%',
                        }, {
                            type: 'PYG',
                            name: `PYG${this.$t('data.five.966565-18')}`,
                            load: '16%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '16%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '16%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '16%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '16%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '16%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '16%',
                        }]
                    }, {
                        type: 'UG',
                        name: 'UG',
                        list: [{
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '16%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '13%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '13%',
                        }]
                    }, {
                        type: 'WE',
                        name: 'WE',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '12%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '12%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '12%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '12%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '12%',
                        }, {
                            type: 'KRW',
                            name: `KRW${this.$t('data.five.966565-21')}`,
                            load: '12%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '12%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '12%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '12%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '12%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '12%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '12%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '12%',
                        }]
                    }, {
                        type: 'WG',
                        name: 'WG',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '9%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '12%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'ZZ',
                        name: 'ZZ',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '11%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '11%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '11%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '11%',
                        }, ]
                    }]
                }, {
                    name: this.$t('data.five.966565-25'),
                    type: 'lottery',
                    platy: [{
                        type: 'BBIN',
                        name: 'BBIN',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '10%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '10%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '10%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '10%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '10%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '10%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '10%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '10%',
                        }]
                    }, {
                        type: 'GW',
                        name: 'GW',
                        list: [{
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '11%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '11%',
                        }]
                    }, {
                        type: 'LOTTERY',
                        name: 'DB LOTTERY',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '10%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '10%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '10%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '10%',
                        }]
                    }, {
                        type: 'QQKENO',
                        name: 'QQKENO',
                        list: [{
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '10%',
                        }]
                    }, {
                        type: 'QQThai',
                        name: 'QQThai',
                        list: [{
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '10%',
                        }]
                    }, {
                        type: 'SABA',
                        name: 'SABA',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '13%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '13%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '13%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '13%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '15%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '13%',
                        }, {
                            type: 'PKR',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '13%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '13%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '13%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '13%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '13%',
                        }]
                    }, {
                        type: 'Spade',
                        name: 'SGwin',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '10%',
                        }]
                    }, {
                        type: 'TCG',
                        name: 'TCG',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '10%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '10%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '10%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '10%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '10%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '10%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '10%',
                        }]
                    }, {
                        type: 'TP',
                        name: 'TP',
                        list: [{
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '10%',
                        }]
                    }, {
                        type: 'WE',
                        name: 'WE',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '11%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '11%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '11%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '11%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '11%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '11%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '11%',
                        }]
                    }, ]
                }, {
                    name: this.$t('data.five.966565-26'),
                    type: 'cockfighting',
                    platy: [{
                        type: 'DS88',
                        name: 'DS88',
                        list: [{
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, ]
                    }, {
                        type: 'SV128',
                        name: 'SV128',
                        list: [{
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '10%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '10%',
                        }, ]
                    }, {
                        type: 'WS168',
                        name: 'WS168',
                        list: [{
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '14%',
                        }, ]
                    }, {
                        type: 'SV388',
                        name: 'SV388',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '17%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '17%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '17%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '17%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '17%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '17%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '17%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '17%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '17%',
                        }]
                    }, ]
                },
                {
                    name: this.$t('data.five.966565-27'),
                    type: 'boxs',
                    platy: [{
                        type: 'GoodFortune',
                        name: 'GoodFortune Gaming',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '9%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'JDB',
                        name: 'JDB',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '9%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '13%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'MAD',
                            name: `MAD${this.$t('data.five.966565-14')}`,
                            load: '13%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '13%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '13%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '9%',
                        }, {
                            type: 'PYG',
                            name: `PYG${this.$t('data.five.966565-18')}`,
                            load: '13%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '13%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'JILI',
                        name: 'JILI',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '9%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'SPRIBE',
                        name: 'SPRIBE',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '13%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '13%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '13%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '13%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '13%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '13%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '13%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '13%',
                        }]
                    }, {
                        type: 'T1',
                        name: 'T1',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '13%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '13%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '13%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '13%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '13%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '13%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '13%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '13%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '13%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '13%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '13%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '13%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '13%',
                        }]
                    }, {
                        type: 'TADA',
                        name: 'TADA',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '13%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '13%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '13%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '13%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '13%',
                        }]
                    }, {
                        type: 'VDD',
                        name: 'VDD',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '9%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '9%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '9%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '9%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '9%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '9%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }]
                    }, {
                        type: 'WG',
                        name: 'WG',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '5%',
                        }, {
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '5%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '5%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '5%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '5%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '5%',
                        }, {
                            type: 'KHR',
                            name: `KHR${this.$t('data.five.966565-20')}`,
                            load: '5%',
                        }, {
                            type: 'KRW',
                            name: `KRW${this.$t('data.five.966565-21')}`,
                            load: '5%',
                        }, {
                            type: 'MAD',
                            name: `MAD${this.$t('data.five.966565-14')}`,
                            load: '5%',
                        }, {
                            type: 'MXN',
                            name: `MXN${this.$t('data.five.966565-15')}`,
                            load: '5%',
                        }, {
                            type: 'NGN',
                            name: `NGN${this.$t('data.five.966565-16')}`,
                            load: '5%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '5%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '5%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '5%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '5%',
                        }]
                    }, {
                        type: 'XGAME',
                        name: 'XGAME',
                        list: [{
                            type: 'BRL',
                            name: `BRL${this.$t('data.five.966565-13')}`,
                            load: '9%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '9%',
                        }, {
                            type: 'PHP',
                            name: `PHP${this.$t('data.five.966565-6')}`,
                            load: '9%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '9%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '9%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '9%',
                        }]
                    }, ]
                },
                {
                    name: this.$t('data.five.966565-28'),
                    type: 'esports',
                    platy: [{
                        type: 'SPORTS',
                        name: 'DB E-SPORTS',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '13%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '13%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '13%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '13%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '13%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '13%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '13%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '13%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '13%',
                        }]
                    }, {
                        type: 'SABA',
                        name: 'SABA',
                        list: [{
                            type: 'BDT',
                            name: `BDT${this.$t('data.five.966565-1')}`,
                            load: '12%',
                        }, {
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '12%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '12%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '12%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '12%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '12%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '12%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '12%',
                        }, {
                            type: 'TRX',
                            name: `TRX${this.$t('data.five.966565-17')}`,
                            load: '12%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '12%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '12%',
                        }]
                    }, {
                        type: 'IA',
                        name: 'IA ESPORT',
                        list: [{
                            type: 'CNY',
                            name: `CNY${this.$t('data.five.966565-2')}`,
                            load: '13%',
                        }, {
                            type: 'IDR',
                            name: `IDR${this.$t('data.five.966565-3')}`,
                            load: '13%',
                        }, {
                            type: 'INR',
                            name: `INR${this.$t('data.five.966565-4')}`,
                            load: '13%',
                        }, {
                            type: 'JPY',
                            name: `JPY${this.$t('data.five.966565-5')}`,
                            load: '13%',
                        }, {
                            type: 'PKR',
                            name: `PKR${this.$t('data.five.966565-7')}`,
                            load: '13%',
                        }, {
                            type: 'RUB',
                            name: `RUB${this.$t('data.five.966565-8')}`,
                            load: '13%',
                        }, {
                            type: 'THB',
                            name: `THB${this.$t('data.five.966565-9')}`,
                            load: '13%',
                        }, {
                            type: 'TRY',
                            name: `BRL${this.$t('data.five.966565-10')}`,
                            load: '13%',
                        }, {
                            type: 'USDT',
                            name: `USDT${this.$t('data.five.966565-11')}`,
                            load: '13%',
                        }, {
                            type: 'VND',
                            name: `VND${this.$t('data.five.966565-12')}`,
                            load: '13%',
                        }]
                    }]
                }
            ]
        },
        fiveLists() {
            let fiveItem = []
                // let paltyNames = [];
            this.fiveList.forEach((item) => {
                let arr = [];

                item.platy.forEach((row) => {
                    row.list.forEach((ite) => {
                        let Obj = {
                            name: item.name,
                            nameType: item.type,
                            platyname: row.name,
                            platyType: row.type,
                            current: ite.name,
                            currentType: ite.type,
                            bl: ite.load,
                        };
                        arr.push(Obj);
                    });
                });

                fiveItem.push(...arr);
            });
            return fiveItem
        },
        gameTypes() {
            let gameTypeArr = [];
            this.fiveList.forEach((item) => {
                gameTypeArr.push({
                    text: item.name,
                    value: item.type,
                });
            })
            return gameTypeArr
        }
    }
}