const main = `<div>
  <p class="c-title">Kính gửi Chủ sở hữu trang web,</p>
  <p>Xin chào!</p>
  <p>&nbsp; &nbsp; Sau hơn 6 tháng cải tiến, phần mềm SaaS hiện tại đã hoàn toàn chuyển đổi sang kiến trúc 4.0 mới. Tỷ lệ chuyển đổi và tỷ lệ duy trì đã tăng đáng kể. Để giúp các chủ sở hữu trang web giảm chi phí, chúng tôi sẽ triển khai các sự kiện sau đây (áp dụng lâu dài):
  </p>
  <p>
    <span style="color: #000000;font-weight: bold;">&nbsp; &nbsp; 1. Hoàn trả toàn bộ phí thiết lập:</span>
   Đối với các trang web được tạo sau ngày 20 tháng 2 năm 2024 (tức là theo mô hình tính phí mới), chỉ hoàn trả cho một trang web duy nhất.
    <span style="color:#ff8800">có hóa đơn hàng tháng là 300.000 U hoặc tổng hóa đơn tích lũy là 1.500.000 U, sẽ được hoàn trả toàn bộ phí thiết lập</span>
    (Khi trùng lặp với các sự kiện bên dưới, tối đa được hoàn trả 100% và không được vượt quá số tiền này)。
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 2. Giảm hoặc miễn phí phí thiết lập: Nếu không đáp ứng được các điều kiện trên, phí thiết lập vẫn có thể được giảm hoặc miễn phí thông qua các phương thức sau:</span>
  </p>
  <p>
    <span style="color：#000000;font-weight: bold">&nbsp; &nbsp; (1) Hệ thống châu Á - Thái Bình Dương:</span>
    Đối với các trang web châu Á - Thái Bình Dương được tạo sau ngày 30 tháng 1 năm 2024, nếu trang web đã hoạt động được 90 ngày, thanh toán hóa đơn đúng hạn và có mức nạp tiền trung bình hàng ngày là 100.000 RMB, bạn có thể đăng ký hoàn lại 25% (tức là 5000 U) phí thiết lập;
   nếu trang web đã hoạt động được 180 ngày, thanh toán hóa đơn đúng hạn và có mức nạp tiền trung bình hàng ngày là 150.000 RMB, bạn có thể đăng ký hoàn lại thêm 50% (tức là 10.000 U) phí thiết lập,
    <span style="color:#ff8800">tức là phí thiết lập thực tế chỉ còn 25% (tức là 5000 U)</span>
  </p>
  <p>
    <span style="color：#000000;font-weight: bold">&nbsp; &nbsp;(2) Hệ thống châu Mỹ:
Đối với các trang web châu Mỹ được tạo sau ngày 1 tháng 6 năm 2024 (sau phiên bản 4.0), nếu trang web đã hoạt động được 90 ngày, thanh toán hóa đơn đúng hạn và có mức nạp tiền trung bình hàng ngày là 200.000 BRL, bạn có thể đăng ký hoàn lại 25% (tức là 5000 U) phí thiết lập; nếu trang web đã hoạt động được 180 ngày, thanh toán hóa đơn đúng hạn và có mức nạp tiền trung bình hàng ngày là 300.000 BRL, bạn có thể đăng ký hoàn lại thêm 50% (tức là 10.000 U) phí thiết lập,
    <span style="color:#ff8800">tức là phí thiết lập thực tế chỉ còn 25% (tức là 5000 U).</span>
  </span></p>
  <p>
    <span style="color：#000000;font-weight: bold">&nbsp; &nbsp;3. Miễn phí phí di chuyển: Đối với các trang web bên ngoài di chuyển sang nền tảng WG, nếu số thành viên hợp lệ được nhập khẩu ≥ 10.000 và mức nạp tiền trung bình trong 30 ngày đầu tiên ≥ 500.000 RMB,
    <span style="color:#ff8800">bạn có thể đăng ký hoàn lại 20.000 U cho phí thiết lập vào số dư của trang web sau 30 ngày, có thể được sử dụng để trừ vào hóa đơn.</span>
  </span></p>
  <p>
    <span style="color：#000000;font-weight: bold">&nbsp; &nbsp;4. Miễn phí phí bảo trì trang web mới:</span>
    Đối với tất cả các trang web mới được tạo, phí bảo trì sẽ được miễn phí trong 30 ngày đầu tiên sau khi hoàn thành trang web. Phí bảo trì 3.000 U/tháng sẽ bắt đầu được tính từ ngày thứ 31.
  </p>
  <p>
    <span style="color：#000000;font-weight: bold">&nbsp; &nbsp;5. Miễn phí đặt cọc: Nếu chủ sở hữu chính có số tiền hóa đơn hàng tháng vượt quá 100.000 U và thanh toán đúng hạn đồng ý cung cấp bảo đảm, bạn có thể đăng ký miễn phí khoản đặt cọc 9.000 U/trang web khi tạo trang web.
  </span></p>
  <p>
    <span style="font-weight: bold;color: #37bc2a;">&nbsp; &nbsp;6. Hoàn lại và miễn phí phí bảo trì [Quan trọng]</span>
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp;(1) Miễn phí phí bảo trì: Nếu hóa đơn của trang web trong tháng là từ 0 đến 10.000 U, sẽ tính toàn bộ phí bảo trì 3.000 U/tháng; nếu hóa đơn từ 10.000 đến 50.000 U, phí bảo trì sẽ là 2.000 U/tháng;
    <span style="color: #008000">nếu hóa đơn là từ 50.000 đến 100.000 U, phí bảo trì sẽ là 1.000 U/tháng;</span>
   nếu hóa đơn vượt quá 100.000 U, phí bảo trì sẽ miễn phí, tức là 0.
  </span></p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; (2) Giảm giá theo chính sách cũ (lên đến 1,2%):</span>
    Áp dụng cho các trang web được tạo trước ngày 20 tháng 2 năm 2024. Mức chiết khấu cho trò chơi của bên thứ ba được xác định theo số tiền hóa đơn của trang web, với tổng cộng 8 bậc,
    <span style="color: #008000">với mức chiết khấu tối đa là 1,2% (tức là nếu số tiền hóa đơn của trang web vượt quá 1.000.000 U trong tháng, phí gốc của PG là 7% sẽ được giảm xuống còn 7% - 1,2% = 5,8%);</span>
    nếu trang web đã được hưởng chiết khấu cố định của bên thứ ba, hệ thống sẽ tự động tính toán theo mức cao nhất. Ví dụ: nếu số tiền hóa đơn của trang web trong tháng là 80.000 U, mức chiết khấu tương ứng là 0,4% và nếu nhóm được hưởng chiết khấu cố định của bên thứ ba là 0,7%, mức chiết khấu tối đa là 0,7% sẽ được sử dụng (tức là tỷ lệ của PG sẽ giảm từ 7% xuống còn 6,3%).
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; (3) Giảm giá theo chính sách mới (lên đến 2,3%):</span>
    Áp dụng cho các trang web được tạo sau ngày 20 tháng 2 năm 2024. Mức chiết khấu cho trò chơi của bên thứ ba được xác định theo số tiền hóa đơn của trang web, với tổng cộng 8 bậc,
    <span style="color: #008000">với mức chiết khấu tối đa là 2,3% (tức là nếu số tiền hóa đơn của trang web vượt quá 1.000.000 U trong tháng, phí gốc của PG là 9% sẽ được giảm xuống còn 9% - 2,3% = 6,7%);</span>
    nếu trang web đã được hưởng chiết khấu cố định của bên thứ ba, hệ thống sẽ tự động tính toán theo mức cao nhất. Ví dụ: nếu số tiền hóa đơn của trang web trong tháng là 40.000 U, mức chiết khấu tương ứng là 0,5% và nếu nhóm được hưởng chiết khấu cố định của bên thứ ba là 1,0%, mức chiết khấu tối đa là 1,0% sẽ được sử dụng (tức là tỷ lệ của PG sẽ giảm từ 9% xuống còn 8%).
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; (4) Bảng so sánh mức chiết khấu: Xem bên dưới</span>
 </p><table style="width:100%" class="wg-table-style">
                    <thead style="background-color: rgba(22, 120, 255, 0.04)">
                      <tr>
                        <th>Cấp Độ</th>
                        <th>Hóa Đơn Hàng Tháng mỗi Trang Web</th>
                        <th>
                          <div>Tỷ Lệ Chiết Khấu Chính Sách Cũ</div>
                          <div style="font-weight:normal">
                            (Cho các trang web được tạo trước ngày 20 tháng 2 năm 2024)
                          </div>
                        </th>
                        <th>
                          <div>Tỷ Lệ Chiết Khấu Chính Sách Mới</div>
                          <div style="font-weight:normal">
                            (Cho các trang web được tạo sau ngày 20 tháng 2 năm 2024)
                          </div>
                        </th>
                        <th>Phí Bảo Trì Hàng Tháng</th>
                      </tr>
                    </thead>
                    <tbody style="text-align:center;line-height:2;">
                      <tr>
                        <td>Cấp Độ 1</td>
                        <td>0~10.000 U</td>
                        <td>0.00%</td>
                        <td>0.00%</td>
                        <td>3000U</td>
                      </tr>
                      <tr>
                        <td>Cấp Độ 2</td>
                        <td>10.000~50.000 U</td>
                        <td>0.30%</td>
                        <td>0.50%</td>
                        <td>2000U</td>
                      </tr>
                      <tr>
                        <td>Cấp Độ 3</td>
                        <td>50.000~100.000 U</td>
                        <td>0.50%</td>
                        <td>1.00%</td>
                        <td>1000U</td>
                      </tr>
                      <tr>
                        <td>Cấp Độ 4</td>
                        <td>100.000~300.000 U</td>
                        <td>0.80%</td>
                        <td>1.50%</td>
                        <td>Miễn phí phí bảo trì</td>
                      </tr>
                      <tr>
                        <td>Cấp Độ 5</td>
                        <td>300.000~500.000 U</td>
                        <td>1.00%</td>
                        <td>2.00%</td>
                        <td>Miễn phí phí bảo trì</td>
                      </tr>
                      <tr>
                        <td>Cấp Độ 6</td>
                        <td>500.000~750.000 U</td>
                        <td>1.05%</td>
                        <td>2.10%</td>
                        <td>Miễn phí phí bảo trì</td>
                      </tr>
                      <tr>
                        <td>Cấp Độ 7</td>
                        <td>750.000~1.000.000 U</td>
                        <td>1.10%</td>
                        <td>2.20%</td>
                        <td>Miễn phí phí bảo trì</td>
                      </tr>
                      <tr>
                        <td>Cấp Độ 8</td>
                        <td>Trên 1.000.000 U</td>
                        <td>1.20%</td>
                        <td>2.30%</td>
                        <td>Miễn phí phí bảo trì</td>
                      </tr>
                    </tbody>
                  </table>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 7. Điều kiện thu hồi chiết khấu:</span>
    Nếu khách hàng thường xuyên quá hạn thanh toán trên 30 ngày, WG có quyền thu hồi lại toàn bộ chiết khấu đã được áp dụng.
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 8. Trải nghiệm miễn phí hoàn toàn các trò chơi tự vận hành:</span>
    Sau gần 6 tháng tối ưu hóa, các trò chơi tự vận hành đã được cải thiện đáng kể về độ ổn định, tốc độ tải và khả năng giữ chân người chơi. Hiện tại, chúng tôi hỗ trợ điều chỉnh tỷ lệ trúng thưởng ở mức 3,5%, 4,0% và 4,5% (chỉ áp dụng cho trò chơi slot). Ngoài ra, tính năng jackpot nhóm cũng đã được thêm vào, cho phép sử dụng trong cùng một trang web để hỗ trợ mở rộng quy mô. Để chào mừng bản cập nhật trò chơi lớn này,
    <span style="color: #008000">Từ ngày 1 tháng 7 năm 2024 đến ngày 31 tháng 8 năm 2024, tất cả các trò chơi tự vận hành của WG bao gồm "Trò chơi bài, Trò chơi Slot, Câu cá và Blockchain" sẽ hoàn toàn miễn phí trong hai tháng, và bất kỳ khoản thua lỗ nào do lỗi trò chơi trong thời gian này sẽ được bồi thường đầy đủ.</span>
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 9. Thông báo trò chơi mới:</span>
    Dựa trên sở thích của thị trường Brazil, các trò chơi tự vận hành của WG đã bổ sung một số trò chơi hot có lối chơi tương tự.
    <span style="color: #eb05f2">(Chim Đại Phú, Ông Rùa, Báo Tiền, Tìm Rồng Bảo Bối, Chó Vàng Phát Lộc),</span>
    <span style="color:#ff8800">sẽ được miễn phí hoàn toàn trong hai tháng.</span>
    Từ ngày 1 tháng 7 năm 2024 đến ngày 31 tháng 8 năm 2024, phí sẽ được cố định vĩnh viễn ở mức 5%
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 10. Giảm giá 10% cho thị trường Ấn Độ:</span>
    PG sẽ tổ chức một sự kiện dành riêng cho thị trường Ấn Độ, giảm giá 10% trên hóa đơn trong suốt thời gian diễn ra sự kiện. Sự kiện diễn ra từ ngày 1 tháng 7 năm 2024 đến ngày 31 tháng 6 năm 2025. Giảm giá này chỉ áp dụng cho hóa đơn trò chơi PG thanh toán bằng đồng Rupee Ấn Độ (INR), ví dụ: phí gốc là 8% sẽ được giảm xuống còn 7,2%.
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 11. Mở rộng toàn diện Hệ thống châu Mỹ:</span>
        Do những hạn chế về kiến trúc hệ thống trước đây, WG đã ngần ngại mở hệ thống châu Mỹ. Hiện tại, hệ thống đã chuyển đổi hoàn toàn sang kiến trúc 4.0, có khả năng hỗ trợ ít nhất 100 triệu người dùng trực tuyến cùng lúc. Do đó, hệ thống châu Mỹ hiện đã được mở hoàn toàn để tạo trang web. Chúng tôi chào đón các lời giới thiệu, người giới thiệu có thể hưởng hoa hồng lợi nhuận ròng lên đến 6% và bên được giới thiệu nhận được giảm giá 2,5% trên hóa đơn trong 6 tháng đầu.
  </p>
  <p style="margin-top:20px;">Trân Trọng</p>
  <div style="text-align:right;margin-top:10px;">
    <div>Cảm ơn và trân trọng,</div>
    <div>WG Metaverse Team</div>
    <div>Cập nhật vào ngày 20 tháng 7 năm 2024</div>
  </div>
</div>`
export default main