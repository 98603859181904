const main = `
<div>
  <p class="c-title">尊敬的站长</p>
  <p>您好！</p>
  <p>&nbsp; &nbsp; 经过近6个多月的完善，当前SaaS已全部切换到4.0全新架构，转化率和留存率都有不少提升，为帮助站长降低成本，将推出以下活动（长期有效）：
  </p>
  <p>
    <span style="color: #000000;font-weight: bold;">&nbsp; &nbsp; 1、全额返还开站费：</span>
    凡是于2024年2月20日之后创建的站点（即新收费模式），只要单个站点
    <span style="color:#ff8800">当月账单达30万U或单个站点累积总账单达150万U，即可全额返还所收取的开站费</span>
    （与下面活动重叠时，最多只退还100%，不能超额退还）。
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 2、退还开站费情形：</span>
    若不符合以上条件，仍可通过以下方式减免开站费。
  </p>
  <p>
    <span style="color：#000000;font-weight: bold">&nbsp; &nbsp; （1）亚太系统：</span>
    自2024年1月30日之后创建的亚太站点，当该站点运营到90天后，期间准时缴付账单，并且日平均充值达到10万rmb的，可申请退还25%（即5000U）开站费；
    当该站点运营到180天后，期间准时缴付账单，并且日平均充值达到15万rmb的，可再申请退还50%（即10000U）的开站费，
    <span style="color:#ff8800">相当于实际只收取25%（即5000U）的开站费。</span>
  </p>
  <p>
    <span style="color：#000000;font-weight: bold">&nbsp; &nbsp;（2）美洲系统：</span>
    自2024年6月1日之后创建的美洲站点（4.0版本之后），当该站点运营到90天后，期间准时缴付账单，并且日平均充值达到20万BRL的，可申请退还25%（即5000U）开站费；当该站点运营到180天后，期间准时缴付账单，并且日平均充值达到30万BRL的，可再申请退还50%（即10000U）的开站费，
    <span style="color:#ff8800">相当于实际只收取25%（即5000U）的开站费。</span>
  </p>
  <p>
    <span style="color：#000000;font-weight: bold">&nbsp; &nbsp;3、迁移免开站费：</span>
    属于外部站点迁移到WG平台的，导入的有效会员≥1万人，且前30天日平均充值≥50万rmb，
    <span style="color:#ff8800">则30天后可申请退还20000U的开站费到站点余额，用于账单抵扣。</span>
  </p>
  <p>
    <span style="color：#000000;font-weight: bold">&nbsp; &nbsp;4、新站免维护费：</span>
    所有新开的站点，建站完成后的前30天免维护费，统一第31天才开始计算收取3000U/月的维护费。
  </p>
  <p>
    <span style="color：#000000;font-weight: bold">&nbsp; &nbsp;5、免押金情形：</span>
   若有月账单金额超10万U且准时缴付的大业主愿意担保，则创建站点时可申请免缴纳9000U/站点的押金。
  </p>
  <p>
    <span style="font-weight: bold;color: #37bc2a;">&nbsp; &nbsp;6、返利和免维护费【重要】</span>
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; （1）免维护费情形：</span>
    当月该站点账单为0-1万U则全额收取维护费3000U/月；当月该站点账单为1-5万U则只收取维护费2000U/月；
    <span style="color: #008000">当月该站点账单为5-10万U则只收取维护费1000U/月；</span>
    当月该站点账单为10万U以上则免收取维护费，即为0。
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; （2）旧政策优惠（最高1.2%）：</span>
    是指于2024年2月20日之前创建的站点，站点账单金额决定三方游戏的优惠比例，共分为8个阶梯，
    <span style="color: #008000">最高可以优惠1.2%（即当月该站点账单金额100万U以上时，PG原收费7%将变为7%-1.2%=5.8%）；</span>
    若当前已享受固定三方优惠，则系统自动按最高等级计算，例如：某站点当月账单优惠为8万U，表格对应优惠比例为0.4%，若集团已享受三方的固定优惠为0.7%，则以最大的优惠为准直接按0.7%计算（即PG由7%变成6.3%）。
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; （3）新政策优惠（最高2.3%）：</span>
    是指于2024年2月20日之后创建的站点，站点账单金额决定三方游戏的优惠比例，共分为8个阶梯，
    <span style="color: #008000">最高可以优惠2.3%（即当月该站点账单金额100万U以上时，PG原收费9%将变为9%-2.3%=6.7%）；</span>
    若当前已享受固定三方优惠，则系统自动按最高等级计算，例如：某站点当月账单优惠为4万U，表格对应优惠比例为0.5%，若集团已享受三方的固定优惠为1.0%，则以最大的优惠为准直接按1.0%计算（即PG由9%变成8%）。
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; （4）优惠比例对照表：如下图</span>
  </p>
    <table style="width:100%" class="wg-table-style">
                    <thead style="background-color: rgba(22, 120, 255, 0.04)">
                      <tr>
                        <th>阶梯</th>
                        <th>当月单站点账单</th>
                        <th>
                          <div>旧政策优惠比例</div>
                          <div style="font-weight:normal">
                            (24年2月20日之前创建的站点)
                          </div>
                        </th>
                        <th>
                          <div>新政策优惠比例</div>
                          <div style="font-weight:normal">
                            (24年2月20日之后创建的站点)
                          </div>
                        </th>
                        <th>月维护费</th>
                      </tr>
                    </thead>
                    <tbody style="text-align:center;line-height:2;">
                      <tr>
                        <td>1档</td>
                        <td>0~1万U</td>
                        <td>0.00%</td>
                        <td>0.00%</td>
                        <td>3000U</td>
                      </tr>
                      <tr>
                        <td>2档</td>
                        <td>1~5万U</td>
                        <td>0.30%</td>
                        <td>0.50%</td>
                        <td>2000U</td>
                      </tr>
                      <tr>
                        <td>3档</td>
                        <td>5~10万U</td>
                        <td>0.50%</td>
                        <td>1.00%</td>
                        <td>1000U</td>
                      </tr>
                      <tr>
                        <td>4档</td>
                        <td>10~30万U</td>
                        <td>0.80%</td>
                        <td>1.50%</td>
                        <td>免维护费</td>
                      </tr>
                      <tr>
                        <td>5档</td>
                        <td>30~50万U</td>
                        <td>1.00%</td>
                        <td>2.00%</td>
                        <td>免维护费</td>
                      </tr>
                      <tr>
                        <td>6档</td>
                        <td>50~75万U</td>
                        <td>1.05%</td>
                        <td>2.10%</td>
                        <td>免维护费</td>
                      </tr>
                      <tr>
                        <td>7档</td>
                        <td>75~100万U</td>
                        <td>1.10%</td>
                        <td>2.20%</td>
                        <td>免维护费</td>
                      </tr>
                      <tr>
                        <td>8档</td>
                        <td>100万U以上</td>
                        <td>1.20%</td>
                        <td>2.30%</td>
                        <td>免维护费</td>
                      </tr>
                    </tbody>
                  </table>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 7、被回收优惠情形：</span>
    若经常性拖欠账单超过30天的，则WG有权回收所有优惠。
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 8、自营游戏完全免费预告：</span>
    经过近6个月的优化，自营游戏不管在稳定性、加载速度、数值留存都有了很大的提高，且支持调整杀率共有3.5%、4.0%和4.5%三档（仅支持电子），同时增加组团瓜分彩金池的功能，只能同一站点进行组队，可帮助站点进行裂变；为了庆祝游戏重大变革，
    <span style="color: #008000">自2024年7月1日起至2024年8月31日，WG自营游戏“棋牌、电子、捕鱼和区块链”将为期2个月完全免交收，且期间因游戏漏洞导致的亏损将给予全额赔偿。</span>
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 9、新游戏公告：</span>
    根据巴西市场的喜好，WG自营游戏已新增几款玩法接近的热门游戏
    <span style="color: #eb05f2">（巨富鸟、海龟先生、金钱豹、寻龙夺宝、旺财狗），</span>
    <span style="color:#ff8800">不仅为期两个月完全免费</span>
    （2024年7月1日-2024年8月31日），且定价永久固定只抽5%
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 10、PG印度市场9折：</span>
    PG官方将针对印度市场举办活动，活动期间账单享受9折优惠，活动时间从2024年7月1日至2025年6月31日，仅限PG游戏印度卢比（INR）币种的账单可享受9折，例如原来收费8%，折扣后收费7.2%。
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 11、全面放开美洲系统：</span>
    因之前系统架构存在瓶颈，导致WG不敢对外开放美洲系统，现已全面切换至4.0架构，至少可承载1亿人同时在线，所以全面开放美洲系统建站，欢迎推介，推介人最高可享受净利润6%的返佣，被推荐人可享有2.5%账单折扣，有效期为前6个月的账单。
  </p>
  <p style="margin-top:20px;">此致</p>
  <div style="text-align:right;margin-top:10px;">
    <div>商祺！致谢！</div>
    <div>WG元宇宙团队</div>
    <div>2024年7月20日更新</div>
  </div>
</div>`
export default main