import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        styleH5: true,
        setlangVal: localStorage.getItem('lang') || 'hk'
    },
    getters: {},
    mutations: {
        setStyleH5(state, val) {
            state.styleH5 = val;
        },
        setlangVals(state, val) {
            state.setlangVal = val;
        },
    },
    actions: {
        setStyleH5Ac(context, val) {
            context.commit("setStyleH5", val);
        },
        setlangValsAc(context, val) {
            context.commit("setlangVals", val);
        },
    },
    modules: {}
})