export default {
    computed: {
        games() {
            return [{
                name: this.$t('games.text.09231850-1'),
                size: '10.9MB',
                bigImg: '3012.png',
                smallImg: require('@/assets/img/games/3012-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: 'https://www.baidu.com'
            }, {
                name: this.$t('games.text.09231850-2'),
                size: '10.7MB',
                bigImg: '3014.png',
                smallImg: require('@/assets/img/games/3014-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-3'),
                size: '13.7MB',
                bigImg: '3015.png',
                smallImg: require('@/assets/img/games/3015-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-4'),
                size: '13MB',
                bigImg: '3016.png',
                smallImg: require('@/assets/img/games/3016-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-5'),
                size: '9.1MB',
                bigImg: '3017.png',
                smallImg: require('@/assets/img/games/3017-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-6'),
                size: '11.6MB',
                bigImg: '3018.png',
                smallImg: require('@/assets/img/games/3018-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-7'),
                size: '12.2MB',
                bigImg: '3011.png',
                smallImg: require('@/assets/img/games/3011-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-8'),
                size: '13.1MB',
                bigImg: '3013.png',
                smallImg: require('@/assets/img/games/3013-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-9'),
                size: '12.7MB',
                bigImg: '3009.png',
                smallImg: require('@/assets/img/games/3009-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-10'),
                size: '14.2MB',
                bigImg: '3010.png',
                smallImg: require('@/assets/img/games/3010-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-11'),
                size: '13.3MB',
                bigImg: '3008.png',
                smallImg: require('@/assets/img/games/3008-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-12'),
                size: '5.6MB',
                bigImg: '5016.png',
                smallImg: require('@/assets/img/games/5016-1.png'),
                rtp: '96.5%',
                type: 'blockchain',
                link: ''
            }, {
                name: this.$t('games.text.09231850-13'),
                size: '5.7MB',
                bigImg: '5017.png',
                smallImg: require('@/assets/img/games/5017-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-14'),
                size: '8MB',
                bigImg: '3007.png',
                smallImg: require('@/assets/img/games/3007-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-15'),
                size: '9.3MB',
                bigImg: '5015.png',
                smallImg: require('@/assets/img/games/5015-1.png'),
                rtp: '96.5%',
                type: 'fish',
                link: ''
            }, {
                name: this.$t('games.text.09231850-16'),
                size: '18.7MB',
                bigImg: '4002.png',
                smallImg: require('@/assets/img/games/4002-1.png'),
                rtp: '96.5%',
                type: 'fish',
                link: ''
            }, {
                name: this.$t('games.text.09231850-17'),
                size: '8.3MB',
                bigImg: '5010.png',
                smallImg: require('@/assets/img/games/5010-1.png'),
                rtp: '96.5%',
                type: 'blockchain',
                link: ''
            }, {
                name: this.$t('games.text.09231850-18'),
                size: '7.4MB',
                bigImg: '5011.png',
                smallImg: require('@/assets/img/games/5011-1.png'),
                rtp: '96.5%',
                type: 'blockchain',
                link: ''
            }, {
                name: this.$t('games.text.09231850-19'),
                size: '9.3MB',
                bigImg: '5012.png',
                smallImg: require('@/assets/img/games/5012-1.png'),
                rtp: '96.5%',
                type: 'blockchain',
                link: ''
            }, {
                name: this.$t('games.text.09231850-20'),
                size: '9.5MB',
                bigImg: '5013.png',
                smallImg: require('@/assets/img/games/5013-1.png'),
                rtp: '96.5%',
                type: 'blockchain',
                link: ''
            }, {
                name: this.$t('games.text.09231850-21'),
                size: '8.7MB',
                bigImg: '5014.png',
                smallImg: require('@/assets/img/games/5014-1.png'),
                rtp: '96.5%',
                type: 'blockchain',
                link: ''
            }, {
                name: this.$t('games.text.09231850-22'),
                size: '8.2MB',
                bigImg: '5008.png',
                smallImg: require('@/assets/img/games/5008-1.png'),
                rtp: '96.5%',
                type: 'blockchain',
                link: ''
            }, {
                name: this.$t('games.text.09231850-23'),
                size: '7.7MB',
                bigImg: '5009.png',
                smallImg: require('@/assets/img/games/5009-1.png'),
                rtp: '',
                type: 'blockchain',
                link: ''
            }, {
                name: this.$t('games.text.09231850-24'),
                size: '10.3MB',
                bigImg: '5004.png',
                smallImg: require('@/assets/img/games/5004-1.png'),
                rtp: '',
                type: 'blockchain',
                link: ''
            }, {
                name: this.$t('games.text.09231850-25'),
                size: '9.8MB',
                bigImg: '5006.png',
                smallImg: require('@/assets/img/games/5006-1.png'),
                rtp: '',
                type: 'blockchain',
                link: ''
            }, {
                name: this.$t('games.text.09231850-26'),
                size: '10.3MB',
                bigImg: '5007.png',
                smallImg: require('@/assets/img/games/5007-1.png'),
                rtp: '',
                type: 'blockchain',
                link: ''
            }, {
                name: this.$t('games.text.09231850-27'),
                size: '8.2MB',
                bigImg: '5001.png',
                smallImg: require('@/assets/img/games/5001-1.png'),
                rtp: '',
                type: 'blockchain',
                link: ''
            }, {
                name: this.$t('games.text.09231850-28'),
                size: '9.2MB',
                bigImg: '5002.png',
                smallImg: require('@/assets/img/games/5002-1.png'),
                rtp: '',
                type: 'blockchain',
                link: ''
            }, {
                name: this.$t('games.text.09231850-29'),
                size: '10.1MB',
                bigImg: '5003.png',
                smallImg: require('@/assets/img/games/5003-1.png'),
                rtp: '',
                type: 'blockchain',
                link: ''
            }, {
                name: this.$t('games.text.09231850-30'),
                size: '9.7MB',
                bigImg: '5005.png',
                smallImg: require('@/assets/img/games/5005-1.png'),
                rtp: '',
                type: 'blockchain',
                link: ''
            }, {
                name: this.$t('games.text.09231850-31'),
                size: '10.2MB',
                bigImg: '2004.png',
                smallImg: require('@/assets/img/games/2004-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-32'),
                size: '10.7MB',
                bigImg: '1001.png',
                smallImg: require('@/assets/img/games/1001-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-33'),
                size: '13.2MB',
                bigImg: '2006.png',
                smallImg: require('@/assets/img/games/2006-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-34'),
                size: '10.4MB',
                bigImg: '1007.png',
                smallImg: require('@/assets/img/games/1007-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-35'),
                size: '12.7MB',
                bigImg: '1009.png',
                smallImg: require('@/assets/img/games/1009-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-36'),
                size: '14.7MB',
                bigImg: '1002.png',
                smallImg: require('@/assets/img/games/1002-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-37'),
                size: '14.7MB',
                bigImg: '1010.png',
                smallImg: require('@/assets/img/games/1010-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-38'),
                size: '11.4MB',
                bigImg: '2003.png',
                smallImg: require('@/assets/img/games/2003-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-39'),
                size: '11.6MB',
                bigImg: '2002.png',
                smallImg: require('@/assets/img/games/2002-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-40'),
                size: '12MB',
                bigImg: '2001.png',
                smallImg: require('@/assets/img/games/2001-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-41'),
                size: '12MB',
                bigImg: '2005.png',
                smallImg: require('@/assets/img/games/2005-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-42'),
                size: '12MB',
                bigImg: '2012.png',
                smallImg: require('@/assets/img/games/2012-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-43'),
                size: '11.1MB',
                bigImg: '1006.png',
                smallImg: require('@/assets/img/games/1006-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-44'),
                size: '10.9MB',
                bigImg: '1005.png',
                smallImg: require('@/assets/img/games/1005-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-45'),
                size: '11.8MB',
                bigImg: '1004.png',
                smallImg: require('@/assets/img/games/1004-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-46'),
                size: '11.1MB',
                bigImg: '1003.png',
                smallImg: require('@/assets/img/games/1003-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-47'),
                size: '11.1MB',
                bigImg: '1011.png',
                smallImg: require('@/assets/img/games/1011-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-48'),
                size: '12.2MB',
                bigImg: '3001.png',
                smallImg: require('@/assets/img/games/3001-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-49'),
                size: '12.6MB',
                bigImg: '3002.png',
                smallImg: require('@/assets/img/games/3002-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-50'),
                size: '12.1MB',
                bigImg: '3003.png',
                smallImg: require('@/assets/img/games/3003-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-51'),
                size: '12.7MB',
                bigImg: '3004.png',
                smallImg: require('@/assets/img/games/3004-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-52'),
                size: '12.1MB',
                bigImg: '3005.png',
                smallImg: require('@/assets/img/games/3005-1.png'),
                rtp: '96.5%',
                type: 'electron',
                link: ''
            }, {
                name: this.$t('games.text.09231850-53'),
                size: '25.3MB',
                bigImg: '4001.png',
                smallImg: require('@/assets/img/games/4001-1.png'),
                rtp: '96.5%',
                type: 'fish',
                link: ''
            }, {
                name: this.$t('games.text.09231850-54'),
                size: '25.3MB',
                bigImg: '2009.png',
                smallImg: require('@/assets/img/games/2009-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-55'),
                size: '16.7MB',
                bigImg: '2007.png',
                smallImg: require('@/assets/img/games/2007-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-56'),
                size: '11.5MB',
                bigImg: '2013.png',
                smallImg: require('@/assets/img/games/2013-1.png'),
                rtp: '',
                type: 'chess',
                link: ''
            }, {
                name: this.$t('games.text.09231850-57'),
                size: '23.2MB',
                bigImg: '4004.png',
                smallImg: require('@/assets/img/games/4004-1.png'),
                rtp: '',
                type: 'fish',
                link: ''
            }]
        }
    }
}