const main = `<div>
  <p class="c-title">Dear Site Owner,</p>
  <p>Hello!</p>
  <p>&nbsp; &nbsp; After more than 6 months of improvements, the current SaaS has fully transitioned to the new 4.0 architecture. Conversion rates and retention rates have significantly increased. To help site owners reduce costs, the following events will be launched (valid for the long term):
  </p>
  <p>
    <span style="color: #000000;font-weight: bold;">&nbsp; &nbsp; 1. Full Refund of Setup Fees:</span>
    For sites created after February 20, 2024 (i.e., under the new charging model), for each single site
    <span style="color:#ff8800"> with a monthly bill of 300,000U or an accumulative total bill of 1,500,000 U, the full setup fee will be refunded</span>
     ( if there is an overlap with the events below, only a maximum of 100% will be refunded, and no over-refund will be allowed)。
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 2. Refund of Setup Fees:</span>
     If the above conditions are not met, setup fees can still be reduced through the following methods.
  </p>
  <p>
    <span style="color：#000000;font-weight: bold">&nbsp; &nbsp; (1) Asia-Pacific Server:</span>
    For Asia-Pacific sites created after January 30, 2024, if the site has been operating for 90 days, with timely bill payments and an average daily deposit of 100,000 RMB, you can apply for a 25% (i.e., 5000 U) refund of the setup fee;
    if the site has been operating for 180 days, with timely bill payments and an average daily deposit of 150,000 RMB, you can further apply for a 50% (i.e., 10,000 U) refund of the setup fee,
    <span style="color:#ff8800">which means the actual setup fee charged is only 25% (i.e., 5000 U)</span>
  </p>
  <p>
    <span style="color：#000000;font-weight: bold">&nbsp; &nbsp;(2) America Server:</span>
    For Americas sites created after June 1, 2024 (after version 4.0), if the site has been operating for 90 days, with timely bill payments and an average daily deposit of 200,000 BRL, you can apply for a 25% (i.e., 5000 U) refund of the setup fee; if the site has been operating for 180 days, with timely bill payments and an average daily deposit of 300,000 BRL, you can further apply for a 50% (i.e., 10,000 U) refund of the setup fee,
    <span style="color:#ff8800">which means the actual setup fee charged is only 25% (i.e., 5000 U).</span>
  </p>
  <p>
    <span style="color：#000000;font-weight: bold">&nbsp; &nbsp;3. Migration Site with no Setup Fee:</span>
    For external sites migrating to the WG platform, if the imported valid members ≥ 10,000 and the average daily deposit in the first 30 days ≥ 500,000 RMB,
    <span style="color:#ff8800">you can apply for a refund of 20,000 U of the setup fee to the site balance 30 days later, which can be used to offset the bill.</span>
  </p>
  <p>
    <span style="color：#000000;font-weight: bold">&nbsp; &nbsp;4. New Site Maintenance Fee Free:</span>
    For all newly created sites, maintenance fees are free for the first 30 days after site completion. Maintenance fees of 3,000 U/month  will be charged from the 31st day.
  </p>
  <p>
    <span style="color：#000000;font-weight: bold">&nbsp; &nbsp;5. Deposit Free:</span>
   If a major owner with a monthly bill amount exceeding 100,000 U and  timely bill payment is willing to vouch, you can apply for the waive of 9,000 U/site deposit when creating the site.
  </p>
  <p>
    <span style="font-weight: bold;color: #37bc2a;">&nbsp; &nbsp;6. Rebates and Maintenance Fee Free [Important]</span>
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; (1) Maintenance Fee Free:</span>
    If the site's bill for the month is 0-10,000 U, full maintenance fee of 3,000 U/month will be charged; if the bill is 10,000-50,000 U, the maintenance fee will be 2,000 U/month;
    <span style="color: #008000">if the bill is 50,000-100,000 U, the maintenance fee will be 1,000 U/month;</span>
    if the bill exceeds 100,000 U, the maintenance fee will be free of charge.
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; (2) Old Policy Discounts (up to 1.2%):</span>
    Referring to sites created before February 20, 2024. the discount rate for third-party games is determined by the site's bill amount, with a total of 8 tiers,
    <span style="color: #008000">with a maximum discount of 1.2% (i.e., if the site's bill amount exceeds 1,000,000 U for the month, PG’s original charge of 7% will be reduced to 7%-1.2%=5.8%);</span>
    if the site already enjoys a fixed third-party discount, the system will automatically calculate based on the highest level. For example: if a site's bill amount for the month is 80,000 U, the corresponding discount rate is 0.4%, and if the group enjoys a fixed third-party discount of 0.7%, the maximum discount of 0.7% will be used (i.e., PG's rate will be reduced from 7% to 6.3%).
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; (3) New Policy Discounts (up to 2.3%):</span>
    Referring to sites created after February 20, 2024. the discount rate for third-party games is determined by the site's bill amount, with a total of 8 tiers,
    <span style="color: #008000">with a maximum discount of 2.3% (i.e., if the site's bill amount exceeds 1,000,000 U for the month, PG’s original charge of 9% will be reduced to 9%-2.3%=6.7%);</span>
    if the site already enjoys a fixed third-party discount, the system will automatically calculate based on the highest level. For example: if a site's bill amount for the month is 40,000 U, the corresponding discount rate is 0.5%, and if the group enjoys a fixed third-party discount of 1.0%, the maximum discount of 1.0% will be used (i.e., PG's rate will be reduced from 9% to 8%).
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; (4) Discount Rate Comparison Table: See below</span>
  </p>
    <table style="width:100%"  class="wg-table-style">
                    <thead style="background-color: rgba(22, 120, 255, 0.04)">
                      <tr>
                        <th>Tier</th>
                        <th>Monthly Bill per Site</th>
                        <th>
                          <div>Old Policy Discount Rate</div>
                          <div style="font-weight:normal">
                            (For sites created before February 20, 2024)
                          </div>
                        </th>
                        <th>
                          <div>New Policy Discount Rate</div>
                          <div style="font-weight:normal">
                            (For sites created after February 20, 2024)
                          </div>
                        </th>
                        <th>Monthly Maintenance Fee</th>
                      </tr>
                    </thead>
                    <tbody style="text-align:center;line-height:2;">
                      <tr>
                        <td>Tier 1</td>
                        <td>0~10,000 U</td>
                        <td>0.00%</td>
                        <td>0.00%</td>
                        <td>3000U</td>
                      </tr>
                      <tr>
                        <td>Tier 2</td>
                        <td>10,000~50,000 U</td>
                        <td>0.30%</td>
                        <td>0.50%</td>
                        <td>2000U</td>
                      </tr>
                      <tr>
                        <td>Tier 3</td>
                        <td>50,000~100,000 U</td>
                        <td>0.50%</td>
                        <td>1.00%</td>
                        <td>1000U</td>
                      </tr>
                      <tr>
                        <td>Tier 4</td>
                        <td>100,000~300,000 U</td>
                        <td>0.80%</td>
                        <td>1.50%</td>
                        <td>Free of maintenance free</td>
                      </tr>
                      <tr>
                        <td>Tier 5</td>
                        <td>300,000~500,000 U</td>
                        <td>1.00%</td>
                        <td>2.00%</td>
                        <td>Free of maintenance free</td>
                      </tr>
                      <tr>
                        <td>Tier 6</td>
                        <td>500,000~750,000 U</td>
                        <td>1.05%</td>
                        <td>2.10%</td>
                        <td>Free of maintenance free</td>
                      </tr>
                      <tr>
                        <td>Tier 7</td>
                        <td>750,000~1,000,000 U</td>
                        <td>1.10%</td>
                        <td>2.20%</td>
                        <td>Free of maintenance free</td>
                      </tr>
                      <tr>
                        <td>Tier 8</td>
                        <td>Over 1,000,000 U</td>
                        <td>1.20%</td>
                        <td>2.30%</td>
                        <td>Free of maintenance free</td>
                      </tr>
                    </tbody>
                  </table>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 7. Discount Recall Conditions:</span>
    If there are frequent overdue bills exceeding 30 days, WG reserves the right to recall all discounts.
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 8. Completely Free Preview of Self-Operated Games:</span>
    After nearly 6 months of optimization, self-operated games have greatly improved in stability, loading speed, and retention, and now support adjustable hit rates of 3.5%, 4.0%, and 4.5% (slot games only). Additionally, a group jackpot feature has been added, which can only be used within the same site to assist in site expansion. To celebrate this major game update,
    <span style="color: #008000">from July 1, 2024, to August 31, 2024, WG’s self-operated games "Card Games, Slots Games, Fishing, and Blockchain" will be completely free of charge for two months, and any losses due to game bugs during this period will be fully compensated.</span>
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 9. New Game Announcement:</span>
    Based on the preferences of the Brazilian market, WG’s self-operated games have added several hot games with similar gameplay
    <span style="color: #eb05f2">(Wealthy Bird, Mr. Turtle, Money Leopard, Dragon Quest, Lucky Dog),</span>
    <span style="color:#ff8800">which will be completely free for two months</span>
    (from July 1, 2024, to August 31, 2024), and the pricing will be permanently fixed at a 5% fee
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 10. PG India Market 10% Discount:</span>
    PG official will hold an event for the Indian market, offering a 10% discount on bills during the event. The event runs from July 1, 2024, to June 31, 2025. This discount applies only to PG game bills in Indian Rupees (INR), e.g., the original charge of 8% will be discounted to 7.2%.
  </p>
  <p>
    <span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 11. Comprehensive Opening of the Americas System:</span>
        Due to previous system architecture bottlenecks, WG was hesitant to open the Americas system. It has now fully transitioned to the 4.0 architecture, capable of supporting at least 100 million online users at the same time. Therefore, the Americas system is now fully open for site creation. Referrals are welcome, with referrers able to enjoy up to 6% net profit commission, and referred parties can receive a 2.5% discount on bills for the first 6 months.
  </p>
  <p style="margin-top:20px;">Best Regards</p>
  <div style="text-align:right;margin-top:10px;">
    <div>Thank you and best regards</div>
    <div>WG Metaverse Team</div>
    <div>Updated on July 20, 2024</div>
  </div>
</div>`
export default main