const main = `<div>
<p class="c-title">เรียนเจ้าของเว็บไซต์</p>
<p>สวัสดีค่ะ!</p>
<p>&nbsp; &nbsp; หลังจากการปรับปรุงพัฒนากว่า 6 เดือนระบบ SaaS ปัจจุบันของเราได้เปลี่ยนเป็นโครงสร้าง 4.0 เรียบร้อยแล้วโดยมีการเพิ่มอัตราการแปลงและอัตราการรักษาอย่างมีนัยสำคัญ เพื่อช่วยลดรายจ่ายให้แก่เจ้าของเว็บไซต์เราได้มีการจัดกิจกรรมดังนี้ (มีผลในระยะยาว):
</p>
<p>
<span style="color: #000000;font-weight: bold;">&nbsp; &nbsp; 1. คืนเงินค่าธรรมเนียมสร้างเว็บไซต์เต็มจำนวน:</span>
สำหรับเว็บไซต์ที่สร้างหลังจากวันที่ 20 กุมภาพันธ์ 2024 (ที่ใช้รูปแบบการคิดค่าธรรมเนียมใหม่) เว็บไซต์เดียวเท่านั้น
<span style="color:#ff8800">มีค่าใช้จ่ายต่อเดือนมากกว่า 300,000 U หรือมีค่าใช้จ่ายทั้งหมดมากกว่า 1,500,000 U จะได้รับเงินค่าธรรมเนียมการสร้างคืนเต็มจำนวน</span>
（หากซ้ำซ้อนกับกิจกรรมด้านล่างจะได้รับเงินคืนสูงสุด 100% และไม่สามารถเกินจำนวนนี้ได้)
</p>
<p>
<span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 2. เงินคืนค่าธรรมเนียมสร้างเว็บไซต์:</span>
หากไม่ตรงตามเงื่อนไขข้างต้นจะยังสามารถลดหรือได้รับการยกเว้นค่าธรรมเนียมสร้างเว็บไซต์ได้ด้วยวิธีดังนี้
</p>
<p>
<span style="color：#000000;font-weight: bold">&nbsp; &nbsp; (1) ระบบเอเชีย-แปซิฟิก:</span>
สำหรับเว็บไซต์เอเชีย-แปซิฟิกที่สร้างระหว่างวันที่ 30 มกราคม 2024 หากเว็บไซต์เปิดใช้งานเป็นเวลา 90 วันและมีการชำระค่าบริการตรงกำหนด รวมถึงมียอดเติมเงินรายวันเฉลี่ย 100,000 หยวนจะสามารถขอรับเงินค่าธรรมเนียมสร้างเว็บไซต์คืน 25% (เท่ากับ 5000 U) ได้
หากเว็บไซต์เปิดใช้งานเป็นเวลา 180 วันและมีการชำระค่าบริการตรงกำหนด รวมถึงมียอดเติมเงินรายวันเฉลี่ย 150,000 หยวนจะสามารถขอรับเงินค่าธรรมเนียมสร้างเว็บไซต์คืนเพิ่มอีก 50% (เท่ากับ 10,000 U) ได้
<span style="color:#ff8800">หมายความว่าค่าธรรมเนียมสร้างเว็บไซต์ที่เรียกเก็บจริงคือ 25% (เท่ากับ 5000 U)</span>
</p>
<p>
<span style="color：#000000;font-weight: bold">&nbsp; &nbsp;(2) ระบบอเมริกา:</span>
สำหรับเว็บไซต์อเมริกาที่สร้างระหว่างวันที่ 1 มิถุนายน 2024 (หลังจากเวอร์ชั่น 4.0) หากเว็บไซต์เปิดใช้งานเป็นเวลา 90 วันและมีการชำระค่าบริการตรงกำหนด รวมถึงมียอดเติมเงินรายวันเฉลี่ย 200,000 เรอัลบราซิลจะสามารถขอรับเงินค่าธรรมเนียมสร้างเว็บไซต์คืน 25% (เท่ากับ 5000 U) ได้ หากเว็บไซต์เปิดใช้งานเป็นเวลา 180 วันและมีการชำระค่าบริการตรงกำหนด รวมถึงมียอดเติมเงินรายวันเฉลี่ย 300,000 เรอัลบราซิลจะสามารถขอรับเงินค่าธรรมเนียมสร้างเว็บไซต์คืนเพิ่มอีก 50% (เท่ากับ 10,000 U) ได้
<span style="color:#ff8800">หมายความว่าค่าธรรมเนียมสร้างเว็บไซต์ที่เรียกเก็บจริงคือ 25% (เท่ากับ 5000 U)</span>
</p>
<p>
<span style="color：#000000;font-weight: bold">&nbsp; &nbsp;3、3. ฟรีค่าธรรมเนียมการย้ายข้อมูล:</span>
สำหรับเว็บไซต์ภายนอกที่ต้องการย้ายข้อมูลมายังแพลตฟอร์ม WG หากจำนวนสมาชิกที่มีผลที่นำเข้า ≥ 10,000 และยอดเติมเงินรายวันภายใน 30 วันแรก ≥ 500,000 หยวน
<span style="color:#ff8800">คุณสามารถขอคืนเงินค่าธรรมเนียมสร้างเว็บไซต์ไปยังยอดคงเหลือของเว็บไซต์ได้ 20,000 U หลังจาก 30 วันสำหรับใช้เป็นส่วนลดบิล</span>
</p>
<p>
<span style="color：#000000;font-weight: bold">&nbsp; &nbsp;4. ฟรีค่าธรรมเนียมบำรุงรักษาเว็บไซต์ใหม่:</span>
สำหรับเว็บไซต์ใหม่จะมีการยกเว้นค่าธรรมเนียมการบำรุงรักษาเว็บไซต์สำหรับ 30 วันแรกหลังจากสร้างเว็บไซต์ ค่าบำรุงรักษาเว็บไซต์จำนวน 3,000 U/เดือนจะเริ่มเก็บค่าบริการตั้งแต่วันที่ 31 เป็นต้นไป
</p>
<p>
<span style="color：#000000;font-weight: bold">&nbsp; &nbsp;5. เติมเงินฟรี:</span>
หากเจ้าของหลักมีค่าใช้บริการตั้งแต่ 100,000 U ขึ้นไปและมีการชำระค่าบริการตรงกำหนดที่ต้องการใช้บริการรับประกันจะสามารถขอเติมเงินฟรี 9,000 U/เว็บไซต์เมื่อสร้างเว็บไซต์ได้
</p>
<p>
<span style="font-weight: bold;color: #37bc2a;">&nbsp; &nbsp;6. ฟรีค่าบำรุงรักษาและเงินคืน [สำคัญ]</span>
</p>
<p>
<span style="color: #000000;font-weight: bold">&nbsp; &nbsp; (1) ฟรีค่าบำรุงรักษา:</span>
หากยอดบิลของเว็บไซต์ต่อเดือนอยู่ระหว่าง 0-10,000 U จะมีการเก็บค่าบำรุงรักษา 3,000 U/เดือน หากยอดบิลของเว็บไซต์ต่อเดือนอยู่ระหว่าง 10,000-50,000 U จะมีการเก็บค่าบำรุงรักษา 2,000 U/เดือน
<span style="color: #008000">หากยอดบิลของเว็บไซต์อยู่ระหว่าง 50,000-100,000 U จะมีการเก็บค่าบำรุงรักษา 1,000 U/เดือน</span>
หากยอดบิลตั้งแต่ 100,000 U ขึ้นไปจะไม่มีการเก็บค่าบำรุงรักษา หมายความว่าเท่ากับ 0
</p>
<p>
<span style="color: #000000;font-weight: bold">&nbsp; &nbsp; (2) อัตราส่วนลดนโยบายเดิม (สูงสุด 1.2%):</span>
สำหรับเว็บไซต์ที่สร้างก่อนวันที่ 20 กุมภาพันธ์ 2024 อัตราส่วนลดเกมภายนอกจะขึ้นอยู่กับยอดบิลของเว็บไซต์โดยมีทั้งหมด 8 ระดับ
<span style="color: #008000">ส่วนลดสูงสุดถึง 1.2% (ตัวอย่าง หากยอดบิลเกิน 1,000,000 U ต่อเดือน โดยปกติแล้ว PG เรียกเก็บค่าธรรมเนียมที่ 7% จะได้รับส่วนลดเท่ากับ 7%-1.2%=5.8%)</span>
หากเว็บไซต์ได้รับส่วนลดคงที่สำหรับเว็บไซต์ภายนอกอยู่แล้วระบบจะคำนวนตามระดับสูงสุดให้โดยอัตโนมัติ ตัวอย่างเช่น หากเว็บไซต์มียอดบิลต่อเดือนเท่ากับ 80,000 U จะได้รับอัตราส่วนลด 0.4% และหากกลุ่มได้รับส่วนลดคงที่เว็บไซต์ภายนอกอยู่แล้วที่ 0.7% จะใช้อัตราส่วนลดสูงสุดที่ 0.7% ในการคำนวณ (หมายถึงอัตราของ PG จะลดลงจาก 7% เป็น 6.3%)
</p>
<p>
<span style="color: #000000;font-weight: bold">&nbsp; &nbsp; (3) อัตราส่วนลดนโยบายใหม่ (สูงสุด 2.3%):</span>
สำหรับเว็บไซต์ที่สร้างหลังวันที่ 20 กุมภาพันธ์ 2024 อัตราส่วนลดเกมภายนอกจะขึ้นอยู่กับยอดบิลของเว็บไซต์โดยมีทั้งหมด 8 ระดับ
<span style="color: #008000">ส่วนลดสูงสุดถึง 2.3% (ตัวอย่าง หากยอดบิลเกิน 1,000,000 U ต่อเดือน โดยปกติแล้ว PG เรียกเก็บค่าธรรมเนียมที่ 9% จะได้รับส่วนลดเท่ากับ 9%-2.3%=6.7%)</span>
หากเว็บไซต์ได้รับส่วนลดคงที่สำหรับเว็บไซต์ภายนอกอยู่แล้วระบบจะคำนวนตามระดับสูงสุดให้โดยอัตโนมัติ ตัวอย่างเช่น หากเว็บไซต์มียอดบิลต่อเดือนเท่ากับ 40,000 U จะได้รับอัตราส่วนลด 0.5% และหากกลุ่มได้รับส่วนลดคงที่เว็บไซต์ภายนอกอยู่แล้วที่ 1.0% จะใช้อัตราส่วนลดสูงสุดที่1.0% ในการคำนวณ (หมายถึงอัตราของ PG จะลดลงจาก 9% เป็น 8%)
</p>
<p>
<span style="color: #000000;font-weight: bold">&nbsp; &nbsp; (4) ตารางเปรียบเทียบอัตราส่วนลด: ดูด้านล่าง</span>
</p>
<table style="width:100%" class="wg-table-style">
<thead style="background-color: rgba(22, 120, 255, 0.04)">
<tr>
<th>ระดับ</th>
<th>บิลรายเดือนต่อเว็บไซต์</th>
<th>
<div>อัตราส่วนลดนโยบายเดิม</div>
<div style="font-weight:normal">
(สำหรับเว็บไซต์ที่สร้างก่อนวันที่ 20 กุมภาพันธ์ 2024)
</div>
</th>
<th>
<div>อัตราส่วนลดนโยบายใหม่</div>
<div style="font-weight:normal">
(สำหรับเว็บไซต์ที่สร้างหลังวันที่ 20 กุมภาพันธ์ 2024)
</div>
</th>
<th>ค่าบำรุงรักษารายเดือน</th>
</tr>
</thead>
<tbody style="text-align:center;line-height:2;">
<tr>
<td>ระดับ 1</td>
<td>0~10,000 U</td>
<td>0.00%</td>
<td>0.00%</td>
<td>3000U</td>
</tr>
<tr>
<td>ระดับ 2</td>
<td>10,000~50,000 U</td>
<td>0.30%</td>
<td>0.50%</td>
<td>2000U</td>
</tr>
<tr>
<td>ระดับ 3</td>
<td>50,000~100,000 U</td>
<td>0.50%</td>
<td>1.00%</td>
<td>1000U</td>
</tr>
<tr>
<td>ระดับ 4</td>
<td>100,000~300,000 U</td>
<td>0.80%</td>
<td>1.50%</td>
<td>ฟรีค่าบำรุงรักษา</td>
</tr>
<tr>
<td>ระดับ 5</td>
<td>300,000~500,000 U</td>
<td>1.00%</td>
<td>2.00%</td>
<td>ฟรีค่าบำรุงรักษา</td>
</tr>
<tr>
<td>ระดับ 6</td>
<td>500,000~750,000 U</td>
<td>1.05%</td>
<td>2.10%</td>
<td>ฟรีค่าบำรุงรักษา</td>
</tr>
<tr>
<td>ระดับ 7</td>
<td>750,000~1,000,000 U</td>
<td>1.10%</td>
<td>2.20%</td>
<td>ฟรีค่าบำรุงรักษา</td>
</tr>
<tr>
<td>ระดับ 8</td>
<td>มากกว่า 1,000,000 U</td>
<td>1.20%</td>
<td>2.30%</td>
<td>ฟรีค่าบำรุงรักษา</td>
</tr>
</tbody>
</table>
<p>
<span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 7. เงื่อนไขการเรียกคืนส่วนลด:</span>
หากมีการค้างชำระยอดบิลเกินกว่า 30 วันเป็นประจำ ทาง WG สงวนสิทธิ์ในการเรียกคืนส่วนลดทั้งหมด
</p>
<p>
<span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 8. เล่นเกมของบริษัทได้ฟรี:</span>
หลังจากการปรับปรุงพัฒนากว่า 6 เดือนทำให้เกมของบริษัทมีความเสถียรยิ่งขึ้น โหลดรวดเร็วขึ้นและมีการรักษาที่ดียิ่งขึ้น อีกทั้งยังรองรับการปรับคิลเรทที่ 3.5% 4.0% และ 4.5% (เฉพาะเกมสล็อตเท่านั้น) นอกจากนี้ยังมีการเพิ่มฟีเจอร์แจ็คพอตกลุ่มที่สามารถใช้ได้ภายในเว็บไซต์เดียวกันเพื่อขยายเว็บไซต์ได้อีกด้วย เพื่อเป็นการเฉลิมฉลองการอัปเดตครั้งยิ่งใหญ่นี้
<span style="color: #008000">ตั้งแต่ 1 กรกฎาคม 2024 ถึง 31 สิงหาคม 2024 เกมของ WG ได้แก่ “เกมไพ่ เกมสล็อต เกมยิงปลาและเกมบล็อกเชน” จะเปิดให้เล่นฟรีเป็นเวลาสองเดือน การสูญเสียใด ๆ ที่เกิดจากบัคในระหว่างนี้จะได้รับการชดเชยเต็มจำนวน</span>
</p>
<p>
<span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 9. ประกาศเกมใหม่:</span>
เราได้เพิ่มเกมมาแรงของ WG มากมายที่มีเกมเพลย์ตามความนิยมของตลาดบราซิล
<span style="color: #eb05f2">(เช่น Wealthy Bird Mr. Turtle Money Leopard Dragon Quest Lucky Dog)</span>
<span style="color:#ff8800">ซึ่งจะเปิดให้เล่นฟรีเป็นเวลาสองเดือน</span>
(จากวันที่ 1 กรกฎาคม 2024 ถึง 31 สิงหาคม 2024) และค่าธรรมเนียมจะคงที่ถาวรที่ 5%
</p>
<p>
<span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 10. ส่วนลด PG 10% สำหรับตลาดอินเดีย:</span>
เว็บไซต์ทางการของ PG จะจัดกิจกรรมสำหรับตลาดอินเดียโดยมอบส่วนลดบิล 10% ในระหว่างช่วงกิจกรรมเริ่มตั้งแต่วันที่ 1 กรกฎราคม 2024 - 31 มิถุนายน 2025 ส่วนลดใช้ได้เฉพาะค่าบริการเกม PG สำหรับสกุลเงินรูปีอินเดีย (INR) เท่านั้น ตัวอย่างเช่น ค่าบริการเดิม 8% จะลดเหลือ 7.2%
</p>
<p>
<span style="color: #000000;font-weight: bold">&nbsp; &nbsp; 11. เปิดระบบอเมริกาอย่างครบวงจร:</span>
เนื่องจากปัญหาคอขวดของโครงสร้างระบบก่อนหน้านี้ทำให้ WG เกิดความลังเลในการเปิดระบบอเมริกา ขณะนี้เราได้มีการอัปเดตโครงสร้างเป็น 4.0 ซึ่งสามารถรองรับผู้ใช้ออนไลน์กว่า 100 ล้านคนในขณะเดียวกันได้ ดังนั้นเราจึงเปิดการสร้างเว็บไซต์ระบบอเมริกาอย่างเต็มตัว คุณสามารถแนะนำระบบของเราเพื่อรับค่าคอมมิชชั่นสูงสุดถึง 6% และผู้ที่ถูกแนะนำจะได้รับส่วนลดบิล 2.5% สำหรับ 6 เดือนแรกอีกด้วย
</p>
<p style="margin-top:20px;">ด้วยความเคารพ</p>
<div style="text-align:right;margin-top:10px;">
<div>ขอบคุณที่ใช้บริการ</div>
<div>ทีมงานเมตาเวิร์ส WG</div>
<div>อัปเดตเมื่อวันที่ 20 กรกฎาคม 2024</div>
</div>
</div>`
export default main