<template>
  <div class="bw-super-2k">
    <ul :style="{ transform: 'translateX(' + bannerVal + 'px)' }">
      <li
        v-for="(item, index) in list"
        :key="index"
        ref="banner"
        :class="{ hover: twosIndex === index }"
      >
        <img :src="item" />
      </li>
    </ul>
    <div class="arrow prve" @click="tabBanner('prve')"></div>
    <div class="arrow next" @click="tabBanner('next')"></div>
  </div>
</template>
<script>
export default {
  name: "BannerNav",
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      twosIndex: 0,
      bannerVal: 0,
    };
  },
  methods: {
    tabBanner(val) {
      const sreenWid =
        document.documentElement.clientWidth || document.body.clientWidth;
      const domWid = this.$refs.banner[0].clientWidth + 10;
      if (sreenWid > 1024) {
        if (val === "prve") {
          // 上一步
          if (this.twosIndex === 0) return;
          this.twosIndex--;
          this.bannerVal = -(domWid * this.twosIndex);
        } else {
          // 下一步
          if (this.twosIndex >= this.list.length - 4) return;
          this.twosIndex++;
          this.bannerVal = -(domWid * this.twosIndex);
        }
      } else {
        this.bannerVal = 0;
        if (val === "prve") {
          // 上一步
          if (this.twosIndex === 0) {
            this.twosIndex = this.list.length - 1;
            return;
          }
          this.twosIndex--;
        } else {
          // 下一步
          if (this.twosIndex >= this.list.length - 1) {
            this.twosIndex = 0;
            return;
          }
          this.twosIndex++;
        }
      }
    },
  },
};
</script>
